.App { /* Base font style */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 16.0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.8500);
  background-color: white;
}

.App .baseFont * { /* Base font style override for UI framework components */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 16.0px;
}

.App .appBg {
  background-color: white;
}

.App .cardBg {
  background-color: #f4f4f4;
}

.App .baseTextBg { /* Base text color as background */
  background-color: rgba(0, 0, 0, 0.8500);
}

.App .secondaryTextColor {
  color: rgba(0, 0, 0, 0.5000);
}

.App .secondaryTextBg {
  background-color: rgba(0, 0, 0, 0.5000);
}

.App .primaryTextColor {
  color: #ffd878;
}

.App .primaryBg {
  background-color: #ffd878;
}

.App .highlightTextColor {
  color: #315765;
}

.App .highlightBg {
  background-color: #315765;
}

@media (display-mode: standalone) {
  .App {
    user-select: none;
  }
}

/* Styles to customize the UI framework plugin (MUI, https://muicss.com) */
.mui-btn {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;
}
.mui-btn--primary {
    background-color: #ffd878;
}
.mui-btn--primary:active, .mui-btn--primary:focus, .mui-btn--primary:hover {
    background-color: #ffda85;
}
.mui-btn--accent {
    background-color: #315765;
}
.mui-btn--accent:active, .mui-btn--accent:focus, .mui-btn--accent:hover {
    background-color: #446875;
}
.mui-textfield {  /* Reset UI framework styling that affects layout */
    margin-bottom: 0;
}
.mui-textfield>input, .mui-textfield>textarea {
    font-size: inherit;
}
.mui-checkbox, .mui-radio {  /* Reset UI framework styling that affects layout */
    margin-top: 0;
    margin-bottom: 0;
}
.mui-select {  /* Reset UI framework styling that affects layout */
    padding-top: 0;
    margin-bottom: 0;
}
.mui-btn+.mui-btn {  /* Reset UI framework styling that affects layout */
    margin-left: 0;
}
.mui-btn--fab {  /* Reset UI framework styling that breaks compositing */
    z-index: auto;
}

@media (min-width: 320px) {
  .mui-container {
    max-width: 320px;
  }
}
@media (min-width: 568px) {
  .mui-container {
    max-width: 568px;
  }
}
@media (min-width: 768px) {
  .mui-container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .mui-container {
    max-width: 1024px;
  }
}

@keyframes fadeIn {
    0%   { opacity: 0.0; }
    100% { opacity: 1.0; }
}
@keyframes slideIn_right {
    0%   { transform: translateX(100%); }
    100% { transform: translateX(0); }
}
@keyframes slideIn_bottom {
    0%   { transform: translateY(100%); }
    100% { transform: translateY(0); }
}

/* System font classes */

.App .systemFontRegular {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}
.App .systemFontBold {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-weight: 600;
}
.App .systemFontItalic {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-style: italic;
}
.App .systemFontBoldItalic {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-weight: 600;
  font-style: italic;
}

/* App-wide text styles specified in React Studio */

.App .actionFont {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 15.0px;
  font-weight: 400;
}
.App .actionFont * {
  font-size: 15.0px;
}
.App .headlineFont {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 20.0px;
  font-weight: 600;
}
.App .headlineFont * {
  font-size: 20.0px;
}
.App .navBar {
  background-color: #ffd878;
  color: black;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 50px;
  min-height: 50px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 18px;
}

.App .navBar a {
  color: black;
}

.App .navBar .title {
  position: absolute;
  width: 100%;
  text-align: center;
  padding: 4px;
}

.App .navBar .backBtn {
  position: absolute;
  padding: 4px;
  margin-left: 14px;
  cursor: pointer;
}

.App .navBar > div {
  margin-top: 7px;
}

.App {
  --tab-bar-height: 54px;
}
@media (display-mode: standalone) {
  .App {
    --tab-bar-height: 90px;
  }
}
.App .tabBar {
  height: var(--tab-bar-height);
  display: flex;
  flex-direction: row;
  pointer-events: auto;
  z-index: 9999;
}
.App .tabBar > * {
  flex: 1 0 auto;
  text-align: center;
  cursor: pointer;
}
.App .tabBar div {
  box-sizing: border-box;
  font-size: 13px; /* default size for tab bar text */
  line-height: 15px;
}
@media (max-width: 767px) {
  .App .tabBar div {
    font-size: 11px; /* small screen size for tab bar text */
    line-height: 13px;
  }
}
.App .tabBar img {
  height: 24px;
  padding-top: 6px;
}
.App .tabBarMenu {
  overflow: hidden;
}
.App .tabBarPageContent {
  overflow: scroll;
  flex: 1;
}
.App .tabBarPageContent > .AppScreen {
  position: relative;
  overflow: scroll;
}
.App .tabBarPageContent > .AppScreen > .background {
  position: fixed;
  left: auto;
  top: auto;
  height: calc(100% - var(--tab-bar-height));
}
.App .tabBarPageContent > .AppScreen > .navBarContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.App .tabBarPageContent > .AppScreen > .navBarContainer > .navBar {
  position: fixed;
  left: auto;
  top: auto;
}
@media (min-width: 568px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
@media (min-width: 768px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
@media (min-width: 1024px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
.AppScreen {
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: auto;
}

.App .background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.App .layoutFlow {
  position: relative;
  width: 100%;
  pointer-events: none;
}
.App .layoutFlow:before {  /* Prevent margin collapse */
  content: "";
  display: table;
  clear: both;
}
.App .layoutFlow .flowRow {
  position: relative;
  width: 100%;
}

.App .screenFgContainer {
  /* Items in the foreground group within a screen are placed with fixed positioning */
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.App .foreground {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.App ul {
  margin: 0;
  padding: 0;
}
.App li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
}
.App .hasNestedComps {
  position: relative;
}
.App .hasNestedComps:after {
  content: "";
  display: table;
  clear: both;
}
.App .hasNestedComps .background {
  position: absolute;
}
.App .hasNestedComps .layoutFlow {
  position: relative;
}
.App .bottomAlignedContent {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.App .blockInputOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: auto;
  z-index: 99999999;
}

.App .marker {
  width: 0px;
  height: 0px;
}

.App .drawer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.App .drawerOpen {
  transform: translateX(0%);
  transition: 0.3s;
}

.App .drawerClosedLeft {
  transform: translateX(-100%);
  transition: 0.3s;
}

.App .drawerClosedRight {
  transform: translateX(100%);
  transition: 0.3s;
}

.App .drawerContent {
  position: fixed;
  height: 100%;
  overflow: hidden;
}

.App .drawerContent > * {
  height: 100%;
}


/* Narrowest size for this element's responsive layout */
  .RegistroScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .RegistroScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .RegistroScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .RegistroScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .RegistroScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .RegistroScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .RegistroScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .RegistroScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .RegistroScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .RegistroScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .RegistroScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .RegistroScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .RegistroScreen > .layoutFlow > .elRoundimage {
    position: relative;
    margin-top: 0px;
    width: 168px;
    margin-left: calc(50.0% - 84px);
  }
  .RegistroScreen > .layoutFlow > .elRoundimage > * { 
  width: 168px;
  height: 168px;
  }
  
@media (min-width: 568px) {
  .RegistroScreen > .layoutFlow > .elRoundimage {
    position: relative;
    margin-top: 0px;
    width: 168px;
    margin-left: calc(50.0% - 84px);
  }
  .RegistroScreen > .layoutFlow > .elRoundimage > * { 
  width: 168px;
  height: 168px;
  }
  
}
@media (min-width: 768px) {
  .RegistroScreen > .layoutFlow > .elRoundimage {
    position: relative;
    margin-top: 0px;
    width: 167px;
    margin-left: calc(50.0% - 84px);
  }
  .RegistroScreen > .layoutFlow > .elRoundimage > * { 
  width: 167px;
  height: 167px;
  }
  
}
@media (min-width: 1024px) {
  .RegistroScreen > .layoutFlow > .elRoundimage {
    position: relative;
    margin-top: 0px;
    width: 167px;
    margin-left: calc(50.0% - 84px);
  }
  .RegistroScreen > .layoutFlow > .elRoundimage > * { 
  width: 167px;
  height: 167px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .RegistroScreen > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 18px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .RegistroScreen > .layoutFlow > .elText > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .RegistroScreen > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 18px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .RegistroScreen > .layoutFlow > .elText > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .RegistroScreen > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 18px;
    width: 237px;
    margin-left: calc(50.0% - 118px);
  }
  .RegistroScreen > .layoutFlow > .elText > * { 
  width: 237px;
  }
  
}
@media (min-width: 1024px) {
  .RegistroScreen > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 18px;
    width: 237px;
    margin-left: calc(50.0% - 118px);
  }
  .RegistroScreen > .layoutFlow > .elText > * { 
  width: 237px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .RegistroScreen > .layoutFlow > .elName {
    position: relative;
    margin-top: 6px;
    width: 226px;
    margin-left: calc(50.0% - 113px);
  }
  .RegistroScreen > .layoutFlow > .elName > * { 
  width: 226px;
  }
  
@media (min-width: 568px) {
  .RegistroScreen > .layoutFlow > .elName {
    position: relative;
    margin-top: 6px;
    width: 226px;
    margin-left: calc(50.0% - 113px);
  }
  .RegistroScreen > .layoutFlow > .elName > * { 
  width: 226px;
  }
  
}
@media (min-width: 768px) {
  .RegistroScreen > .layoutFlow > .elName {
    position: relative;
    margin-top: 6px;
    width: 225px;
    margin-left: calc(50.0% - 112px);
  }
  .RegistroScreen > .layoutFlow > .elName > * { 
  width: 225px;
  }
  
}
@media (min-width: 1024px) {
  .RegistroScreen > .layoutFlow > .elName {
    position: relative;
    margin-top: 6px;
    width: 225px;
    margin-left: calc(50.0% - 112px);
  }
  .RegistroScreen > .layoutFlow > .elName > * { 
  width: 225px;
  }
  
}

.RegistroScreen .elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.RegistroScreen .elButton button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .RegistroScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 25px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .RegistroScreen > .layoutFlow > .elButton > * { 
  width: 145px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .RegistroScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 25px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .RegistroScreen > .layoutFlow > .elButton > * { 
  width: 145px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .RegistroScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 25px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .RegistroScreen > .layoutFlow > .elButton > * { 
  width: 144px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .RegistroScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 25px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .RegistroScreen > .layoutFlow > .elButton > * { 
  width: 144px;
  height: 35px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .RegistroScreen > .layoutFlow > .elPicker {
    position: relative;
    margin-top: 60px;
    width: 181px;
    margin-left: calc(50.0% - 90px);
  }
  .RegistroScreen > .layoutFlow > .elPicker > * { 
  width: 181px;
  }
  
@media (min-width: 568px) {
  .RegistroScreen > .layoutFlow > .elPicker {
    position: relative;
    margin-top: 60px;
    width: 181px;
    margin-left: calc(50.0% - 90px);
  }
  .RegistroScreen > .layoutFlow > .elPicker > * { 
  width: 181px;
  }
  
}
@media (min-width: 768px) {
  .RegistroScreen > .layoutFlow > .elPicker {
    position: relative;
    margin-top: 60px;
    width: 180px;
    margin-left: calc(50.0% - 90px);
  }
  .RegistroScreen > .layoutFlow > .elPicker > * { 
  width: 180px;
  }
  
}
@media (min-width: 1024px) {
  .RegistroScreen > .layoutFlow > .elPicker {
    position: relative;
    margin-top: 60px;
    width: 180px;
    margin-left: calc(50.0% - 90px);
  }
  .RegistroScreen > .layoutFlow > .elPicker > * { 
  width: 180px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .RegistroScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    position: absolute;
    left: -22px;
    top: 548px;
    width: 23px;
    height: 23px;
  }
@media (min-width: 568px) {
  .RegistroScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    left: -22px;
    top: 548px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 768px) {
  .RegistroScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    left: -22px;
    top: 545px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 1024px) {
  .RegistroScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    left: -22px;
    top: 545px;
    width: 23px;
    height: 23px;
  }
}


/* Narrowest size for this element's responsive layout */
  .RegistroScreen > .screenFgContainer > .foreground > .elTweetnacl {
    position: absolute;
    left: 0px;
    top: 549px;
    width: 23px;
    height: 23px;
  }
@media (min-width: 568px) {
  .RegistroScreen > .screenFgContainer > .foreground > .elTweetnacl {
    left: 0px;
    top: 549px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 768px) {
  .RegistroScreen > .screenFgContainer > .foreground > .elTweetnacl {
    left: 0px;
    top: 546px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 1024px) {
  .RegistroScreen > .screenFgContainer > .foreground > .elTweetnacl {
    left: 0px;
    top: 546px;
    width: 23px;
    height: 23px;
  }
}


/* Narrowest size for this element's responsive layout */
  .InicioScreen > .background > .elText {
    line-height: 19px;
    position: absolute;
    left: 190px;
    top: 0px;
    width: 10px;
    height: 22px;
  }
@media (min-width: 568px) {
  .InicioScreen > .background > .elText {
    line-height: 19px;
    left: 190px;
    top: 0px;
    width: 10px;
    height: 22px;
  }
}
@media (min-width: 768px) {
  .InicioScreen > .background > .elText {
    line-height: 19px;
    left: 189px;
    top: 0px;
    width: 10px;
    height: 22px;
  }
}
@media (min-width: 1024px) {
  .InicioScreen > .background > .elText {
    line-height: 19px;
    left: 189px;
    top: 0px;
    width: 10px;
    height: 22px;
  }
}


/* Narrowest size for this element's responsive layout */
  .InicioScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .InicioScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .InicioScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .InicioScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .InicioScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .InicioScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .InicioScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .InicioScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .InicioScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .InicioScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .InicioScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .InicioScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}

.InicioScreen .elComp {
}

.InicioScreen .elComp.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .InicioScreen > .layoutFlow > .elComp {
    position: relative;
    margin-top: -3px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .InicioScreen > .layoutFlow > .elComp > * { 
    width: 100%;
  }
  
  .InicioScreen > .layoutFlow > .elComp > .background > .containerMinHeight {
    min-height: 132px;
  }

@media (min-width: 568px) {
  .InicioScreen > .layoutFlow > .elComp {
    position: relative;
    margin-top: -3px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .InicioScreen > .layoutFlow > .elComp > * { 
    width: 100%;
  }
  
  .InicioScreen > .layoutFlow > .elComp > .background > .containerMinHeight {
    min-height: 132px;
  }

}
@media (min-width: 768px) {
  .InicioScreen > .layoutFlow > .elComp {
    position: relative;
    margin-top: -3px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .InicioScreen > .layoutFlow > .elComp > * { 
    width: 100%;
  }
  
  .InicioScreen > .layoutFlow > .elComp > .background > .containerMinHeight {
    min-height: 131px;
  }

}
@media (min-width: 1024px) {
  .InicioScreen > .layoutFlow > .elComp {
    position: relative;
    margin-top: -3px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .InicioScreen > .layoutFlow > .elComp > * { 
    width: 100%;
  }
  
  .InicioScreen > .layoutFlow > .elComp > .background > .containerMinHeight {
    min-height: 131px;
  }

}

.InicioScreen .elComp2 {
}

.InicioScreen .elComp2.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .InicioScreen > .layoutFlow > .elComp2 {
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .InicioScreen > .layoutFlow > .elComp2 > * { 
    width: 100%;
  }
  
  .InicioScreen > .layoutFlow > .elComp2 > .background > .containerMinHeight {
    min-height: 86px;
  }

@media (min-width: 568px) {
  .InicioScreen > .layoutFlow > .elComp2 {
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .InicioScreen > .layoutFlow > .elComp2 > * { 
    width: 100%;
  }
  
  .InicioScreen > .layoutFlow > .elComp2 > .background > .containerMinHeight {
    min-height: 86px;
  }

}
@media (min-width: 768px) {
  .InicioScreen > .layoutFlow > .elComp2 {
    position: relative;
    margin-top: 9px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .InicioScreen > .layoutFlow > .elComp2 > * { 
    width: 100%;
  }
  
  .InicioScreen > .layoutFlow > .elComp2 > .background > .containerMinHeight {
    min-height: 86px;
  }

}
@media (min-width: 1024px) {
  .InicioScreen > .layoutFlow > .elComp2 {
    position: relative;
    margin-top: 9px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .InicioScreen > .layoutFlow > .elComp2 > * { 
    width: 100%;
  }
  
  .InicioScreen > .layoutFlow > .elComp2 > .background > .containerMinHeight {
    min-height: 86px;
  }

}

.InicioScreen .elFavorsCard {
}

.InicioScreen .elFavorsCard.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .InicioScreen > .layoutFlow > .elFavorsCard {
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .InicioScreen > .layoutFlow > .elFavorsCard > * { 
    width: 100%;
  }
  
  .InicioScreen > .layoutFlow > .elFavorsCard > .background > .containerMinHeight {
    min-height: 158px;
  }

@media (min-width: 568px) {
  .InicioScreen > .layoutFlow > .elFavorsCard {
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .InicioScreen > .layoutFlow > .elFavorsCard > * { 
    width: 100%;
  }
  
  .InicioScreen > .layoutFlow > .elFavorsCard > .background > .containerMinHeight {
    min-height: 157px;
  }

}
@media (min-width: 768px) {
  .InicioScreen > .layoutFlow > .elFavorsCard {
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .InicioScreen > .layoutFlow > .elFavorsCard > * { 
    width: 100%;
  }
  
  .InicioScreen > .layoutFlow > .elFavorsCard > .background > .containerMinHeight {
    min-height: 157px;
  }

}
@media (min-width: 1024px) {
  .InicioScreen > .layoutFlow > .elFavorsCard {
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .InicioScreen > .layoutFlow > .elFavorsCard > * { 
    width: 100%;
  }
  
  .InicioScreen > .layoutFlow > .elFavorsCard > .background > .containerMinHeight {
    min-height: 157px;
  }

}

.InicioScreen .elComp4 {
}

.InicioScreen .elComp4.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .InicioScreen > .layoutFlow > .elComp4 {
    position: relative;
    margin-top: 7px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .InicioScreen > .layoutFlow > .elComp4 > * { 
    width: 100%;
  }
  
  .InicioScreen > .layoutFlow > .elComp4 > .background > .containerMinHeight {
    min-height: 99px;
  }

@media (min-width: 568px) {
  .InicioScreen > .layoutFlow > .elComp4 {
    position: relative;
    margin-top: 7px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .InicioScreen > .layoutFlow > .elComp4 > * { 
    width: 100%;
  }
  
  .InicioScreen > .layoutFlow > .elComp4 > .background > .containerMinHeight {
    min-height: 99px;
  }

}
@media (min-width: 768px) {
  .InicioScreen > .layoutFlow > .elComp4 {
    position: relative;
    margin-top: 7px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .InicioScreen > .layoutFlow > .elComp4 > * { 
    width: 100%;
  }
  
  .InicioScreen > .layoutFlow > .elComp4 > .background > .containerMinHeight {
    min-height: 99px;
  }

}
@media (min-width: 1024px) {
  .InicioScreen > .layoutFlow > .elComp4 {
    position: relative;
    margin-top: 7px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .InicioScreen > .layoutFlow > .elComp4 > * { 
    width: 100%;
  }
  
  .InicioScreen > .layoutFlow > .elComp4 > .background > .containerMinHeight {
    min-height: 99px;
  }

}

.InicioScreen .elComp3 {
}

.InicioScreen .elComp3.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .InicioScreen > .layoutFlow > .elComp3 {
    position: relative;
    margin-top: 6px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .InicioScreen > .layoutFlow > .elComp3 > * { 
    width: 100%;
  }
  
  .InicioScreen > .layoutFlow > .elComp3 > .background > .containerMinHeight {
    min-height: 81px;
  }

@media (min-width: 568px) {
  .InicioScreen > .layoutFlow > .elComp3 {
    position: relative;
    margin-top: 6px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .InicioScreen > .layoutFlow > .elComp3 > * { 
    width: 100%;
  }
  
  .InicioScreen > .layoutFlow > .elComp3 > .background > .containerMinHeight {
    min-height: 81px;
  }

}
@media (min-width: 768px) {
  .InicioScreen > .layoutFlow > .elComp3 {
    position: relative;
    margin-top: 6px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .InicioScreen > .layoutFlow > .elComp3 > * { 
    width: 100%;
  }
  
  .InicioScreen > .layoutFlow > .elComp3 > .background > .containerMinHeight {
    min-height: 81px;
  }

}
@media (min-width: 1024px) {
  .InicioScreen > .layoutFlow > .elComp3 {
    position: relative;
    margin-top: 6px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .InicioScreen > .layoutFlow > .elComp3 > * { 
    width: 100%;
  }
  
  .InicioScreen > .layoutFlow > .elComp3 > .background > .containerMinHeight {
    min-height: 81px;
  }

}

.InicioScreen .elFab {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.InicioScreen .elFab:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .InicioScreen > .screenFgContainer > .foreground > .elFab {
    line-height: 68px;
    position: fixed;
    left: 11px;
    bottom: 11px;
    width: 68px;
    height: 68px;
  }
@media (min-width: 568px) {
  .InicioScreen > .screenFgContainer > .foreground > .elFab {
    line-height: 68px;
    left: 395px;
    bottom: 11px;
    width: 68px;
    height: 68px;
  }
}
@media (min-width: 768px) {
  .InicioScreen > .screenFgContainer > .foreground > .elFab {
    line-height: 68px;
    left: 11px;
    bottom: 11px;
    width: 68px;
    height: 68px;
  }
}
@media (min-width: 1024px) {
  .InicioScreen > .screenFgContainer > .foreground > .elFab {
    line-height: 68px;
    left: 11px;
    bottom: 11px;
    width: 68px;
    height: 68px;
  }
}

.InicioScreen .elFab2 {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.InicioScreen .elFab2:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .InicioScreen > .screenFgContainer > .foreground > .elFab2 {
    line-height: 68px;
    position: fixed;
    right: 14px;
    bottom: 12px;
    width: 68px;
    height: 68px;
  }
@media (min-width: 568px) {
  .InicioScreen > .screenFgContainer > .foreground > .elFab2 {
    line-height: 68px;
    right: 14px;
    bottom: 12px;
    width: 68px;
    height: 68px;
  }
}
@media (min-width: 768px) {
  .InicioScreen > .screenFgContainer > .foreground > .elFab2 {
    line-height: 68px;
    right: 14px;
    bottom: 12px;
    width: 68px;
    height: 68px;
  }
}
@media (min-width: 1024px) {
  .InicioScreen > .screenFgContainer > .foreground > .elFab2 {
    line-height: 68px;
    right: 14px;
    bottom: 12px;
    width: 68px;
    height: 68px;
  }
}


/* Narrowest size for this element's responsive layout */
  .InicioScreen > .screenFgContainer > .foreground > .elImage {
    position: fixed;
    right: 12px;
    top: 7px;
    width: 34px;
    height: 34px;
  }
@media (min-width: 568px) {
  .InicioScreen > .screenFgContainer > .foreground > .elImage {
    right: 12px;
    top: 7px;
    width: 34px;
    height: 34px;
  }
}
@media (min-width: 768px) {
  .InicioScreen > .screenFgContainer > .foreground > .elImage {
    right: 12px;
    top: 7px;
    width: 34px;
    height: 34px;
  }
}
@media (min-width: 1024px) {
  .InicioScreen > .screenFgContainer > .foreground > .elImage {
    right: 12px;
    top: 7px;
    width: 34px;
    height: 34px;
  }
}


/* Narrowest size for this element's responsive layout */
  .InicioScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    position: absolute;
    left: 296px;
    top: 585px;
    width: 23px;
    height: 23px;
  }
@media (min-width: 568px) {
  .InicioScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    left: 296px;
    top: 585px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 768px) {
  .InicioScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    left: 294px;
    top: 581px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 1024px) {
  .InicioScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    left: 294px;
    top: 581px;
    width: 23px;
    height: 23px;
  }
}


/* Narrowest size for this element's responsive layout */
  .RecibirUnFavorScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .RecibirUnFavorScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .RecibirUnFavorScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .RecibirUnFavorScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .RecibirUnFavorScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .RecibirUnFavorScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .RecibirUnFavorScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .RecibirUnFavorScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .RecibirUnFavorScreen > .layoutFlow > .elQRReader2 {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .RecibirUnFavorScreen > .layoutFlow > .elQRReader2 > * { 
    width: 100%;
  height: 375px;
  }
  
@media (min-width: 568px) {
  .RecibirUnFavorScreen > .layoutFlow > .elQRReader2 {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .RecibirUnFavorScreen > .layoutFlow > .elQRReader2 > * { 
    width: 100%;
  height: 375px;
  }
  
}
@media (min-width: 768px) {
  .RecibirUnFavorScreen > .layoutFlow > .elQRReader2 {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .RecibirUnFavorScreen > .layoutFlow > .elQRReader2 > * { 
    width: 100%;
  height: 373px;
  }
  
}
@media (min-width: 1024px) {
  .RecibirUnFavorScreen > .layoutFlow > .elQRReader2 {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .RecibirUnFavorScreen > .layoutFlow > .elQRReader2 > * { 
    width: 100%;
  height: 373px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .RecibirUnFavorScreen > .screenFgContainer > .foreground > .elTextBlock {
    line-height: 19px;
    position: absolute;
    left: calc(50.5% + 0px - 144px);
    top: 442px;
    width: 288px;
    height: 41px;
  }
@media (min-width: 568px) {
  .RecibirUnFavorScreen > .screenFgContainer > .foreground > .elTextBlock {
    line-height: 19px;
    left: calc(50.5% + 0px - 144px);
    top: 442px;
    width: 288px;
    height: 41px;
  }
}
@media (min-width: 768px) {
  .RecibirUnFavorScreen > .screenFgContainer > .foreground > .elTextBlock {
    line-height: 19px;
    left: calc(50.5% + 0px - 143px);
    top: 440px;
    width: 286px;
    height: 41px;
  }
}
@media (min-width: 1024px) {
  .RecibirUnFavorScreen > .screenFgContainer > .foreground > .elTextBlock {
    line-height: 19px;
    left: calc(50.5% + 0px - 143px);
    top: 440px;
    width: 286px;
    height: 41px;
  }
}


/* Narrowest size for this element's responsive layout */
  .RecibirUnFavorScreen > .screenFgContainer > .foreground > .elTextBlock2 {
    line-height: 19px;
    position: absolute;
    left: calc(50.5% + 0px - 135px);
    top: 493px;
    width: 270px;
    height: 22px;
  }
@media (min-width: 568px) {
  .RecibirUnFavorScreen > .screenFgContainer > .foreground > .elTextBlock2 {
    line-height: 19px;
    left: calc(50.5% + 0px - 135px);
    top: 493px;
    width: 270px;
    height: 22px;
  }
}
@media (min-width: 768px) {
  .RecibirUnFavorScreen > .screenFgContainer > .foreground > .elTextBlock2 {
    line-height: 19px;
    left: calc(50.5% + 0px - 134px);
    top: 490px;
    width: 268px;
    height: 22px;
  }
}
@media (min-width: 1024px) {
  .RecibirUnFavorScreen > .screenFgContainer > .foreground > .elTextBlock2 {
    line-height: 19px;
    left: calc(50.5% + 0px - 134px);
    top: 490px;
    width: 268px;
    height: 22px;
  }
}


/* Narrowest size for this element's responsive layout */
  .HiceUnFavorScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .HiceUnFavorScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .HiceUnFavorScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .HiceUnFavorScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HiceUnFavorScreen > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 18px;
    width: 238px;
    margin-left: 70px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elText > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .HiceUnFavorScreen > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 18px;
    width: 238px;
    margin-left: 70px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elText > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .HiceUnFavorScreen > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 18px;
    width: 237px;
    margin-left: 70px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elText > * { 
  width: 237px;
  }
  
}
@media (min-width: 1024px) {
  .HiceUnFavorScreen > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 18px;
    width: 237px;
    margin-left: 70px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elText > * { 
  width: 237px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elImage {
    position: absolute;
    margin-top: -32px;
    width: 66px;
    margin-left: 10px;
  }
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elImage > * { 
  width: 66px;
  height: 65px;
  }
  
  .flowRow_HiceUnFavorScreen_elImage_773268 { 
  }
  
@media (min-width: 568px) {
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elImage {
    position: absolute;
    margin-top: -32px;
    width: 66px;
    margin-left: 10px;
  }
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elImage > * { 
  width: 66px;
  height: 65px;
  }
  
  .flowRow_HiceUnFavorScreen_elImage_773268 { 
  }
  
}
@media (min-width: 768px) {
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elImage {
    position: absolute;
    margin-top: -32px;
    width: 66px;
    margin-left: 10px;
  }
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elImage > * { 
  width: 66px;
  height: 65px;
  }
  
  .flowRow_HiceUnFavorScreen_elImage_773268 { 
  }
  
}
@media (min-width: 1024px) {
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elImage {
    position: absolute;
    margin-top: -32px;
    width: 66px;
    margin-left: 10px;
  }
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elImage > * { 
  width: 66px;
  height: 65px;
  }
  
  .flowRow_HiceUnFavorScreen_elImage_773268 { 
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HiceUnFavorScreen > .layoutFlow > .elReceived_by {
    position: relative;
    margin-top: 3px;
    margin-left: 75px;
    margin-right: 20px;
    width: calc(100.0% - 95px);
  }
  .HiceUnFavorScreen > .layoutFlow > .elReceived_by > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .HiceUnFavorScreen > .layoutFlow > .elReceived_by {
    position: relative;
    margin-top: 3px;
    margin-left: 75px;
    margin-right: 20px;
    width: calc(100.0% - 95px);
  }
  .HiceUnFavorScreen > .layoutFlow > .elReceived_by > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .HiceUnFavorScreen > .layoutFlow > .elReceived_by {
    position: relative;
    margin-top: 3px;
    margin-left: 74px;
    margin-right: 20px;
    width: calc(100.0% - 94px);
  }
  .HiceUnFavorScreen > .layoutFlow > .elReceived_by > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .HiceUnFavorScreen > .layoutFlow > .elReceived_by {
    position: relative;
    margin-top: 3px;
    margin-left: 74px;
    margin-right: 20px;
    width: calc(100.0% - 94px);
  }
  .HiceUnFavorScreen > .layoutFlow > .elReceived_by > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HiceUnFavorScreen > .layoutFlow > .elText4 {
    line-height: 19px;
    position: relative;
    margin-top: 28px;
    margin-left: 30px;
    margin-right: 20px;
    width: calc(100.0% - 50px);
  }
  .HiceUnFavorScreen > .layoutFlow > .elText4 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .HiceUnFavorScreen > .layoutFlow > .elText4 {
    line-height: 19px;
    position: relative;
    margin-top: 28px;
    margin-left: 30px;
    margin-right: 20px;
    width: calc(100.0% - 50px);
  }
  .HiceUnFavorScreen > .layoutFlow > .elText4 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .HiceUnFavorScreen > .layoutFlow > .elText4 {
    line-height: 19px;
    position: relative;
    margin-top: 28px;
    margin-left: 30px;
    margin-right: 20px;
    width: calc(100.0% - 50px);
  }
  .HiceUnFavorScreen > .layoutFlow > .elText4 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .HiceUnFavorScreen > .layoutFlow > .elText4 {
    line-height: 19px;
    position: relative;
    margin-top: 28px;
    margin-left: 30px;
    margin-right: 20px;
    width: calc(100.0% - 50px);
  }
  .HiceUnFavorScreen > .layoutFlow > .elText4 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HiceUnFavorScreen > .layoutFlow > .elFavor_request {
    position: relative;
    margin-top: 7px;
    margin-left: 30px;
    margin-right: 20px;
    width: calc(100.0% - 50px);
  }
  .HiceUnFavorScreen > .layoutFlow > .elFavor_request > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .HiceUnFavorScreen > .layoutFlow > .elFavor_request {
    position: relative;
    margin-top: 7px;
    margin-left: 30px;
    margin-right: 20px;
    width: calc(100.0% - 50px);
  }
  .HiceUnFavorScreen > .layoutFlow > .elFavor_request > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .HiceUnFavorScreen > .layoutFlow > .elFavor_request {
    position: relative;
    margin-top: 7px;
    margin-left: 30px;
    margin-right: 20px;
    width: calc(100.0% - 50px);
  }
  .HiceUnFavorScreen > .layoutFlow > .elFavor_request > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .HiceUnFavorScreen > .layoutFlow > .elFavor_request {
    position: relative;
    margin-top: 7px;
    margin-left: 30px;
    margin-right: 20px;
    width: calc(100.0% - 50px);
  }
  .HiceUnFavorScreen > .layoutFlow > .elFavor_request > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HiceUnFavorScreen > .layoutFlow > .elText5 {
    line-height: 19px;
    position: relative;
    margin-top: 8px;
    width: 238px;
    margin-left: 30px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elText5 > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .HiceUnFavorScreen > .layoutFlow > .elText5 {
    line-height: 19px;
    position: relative;
    margin-top: 8px;
    width: 238px;
    margin-left: 30px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elText5 > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .HiceUnFavorScreen > .layoutFlow > .elText5 {
    line-height: 19px;
    position: relative;
    margin-top: 8px;
    width: 237px;
    margin-left: 30px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elText5 > * { 
  width: 237px;
  }
  
}
@media (min-width: 1024px) {
  .HiceUnFavorScreen > .layoutFlow > .elText5 {
    line-height: 19px;
    position: relative;
    margin-top: 8px;
    width: 237px;
    margin-left: 30px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elText5 > * { 
  width: 237px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HiceUnFavorScreen > .layoutFlow > .elSkill {
    position: relative;
    margin-top: 9px;
    margin-left: 30px;
    margin-right: 20px;
    width: calc(100.0% - 50px);
  }
  .HiceUnFavorScreen > .layoutFlow > .elSkill > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .HiceUnFavorScreen > .layoutFlow > .elSkill {
    position: relative;
    margin-top: 9px;
    margin-left: 30px;
    margin-right: 20px;
    width: calc(100.0% - 50px);
  }
  .HiceUnFavorScreen > .layoutFlow > .elSkill > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .HiceUnFavorScreen > .layoutFlow > .elSkill {
    position: relative;
    margin-top: 9px;
    margin-left: 30px;
    margin-right: 20px;
    width: calc(100.0% - 50px);
  }
  .HiceUnFavorScreen > .layoutFlow > .elSkill > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .HiceUnFavorScreen > .layoutFlow > .elSkill {
    position: relative;
    margin-top: 9px;
    margin-left: 30px;
    margin-right: 20px;
    width: calc(100.0% - 50px);
  }
  .HiceUnFavorScreen > .layoutFlow > .elSkill > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HiceUnFavorScreen > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 8px;
    width: 238px;
    margin-left: 30px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elText2 > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .HiceUnFavorScreen > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 8px;
    width: 238px;
    margin-left: 30px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elText2 > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .HiceUnFavorScreen > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 8px;
    width: 237px;
    margin-left: 30px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elText2 > * { 
  width: 237px;
  }
  
}
@media (min-width: 1024px) {
  .HiceUnFavorScreen > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 8px;
    width: 237px;
    margin-left: 30px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elText2 > * { 
  width: 237px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HiceUnFavorScreen > .layoutFlow > .elHoras {
    position: relative;
    margin-top: 15px;
    width: 80px;
    margin-left: 30px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elHoras > * { 
  width: 80px;
  }
  
@media (min-width: 568px) {
  .HiceUnFavorScreen > .layoutFlow > .elHoras {
    position: relative;
    margin-top: 15px;
    width: 80px;
    margin-left: 30px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elHoras > * { 
  width: 80px;
  }
  
}
@media (min-width: 768px) {
  .HiceUnFavorScreen > .layoutFlow > .elHoras {
    position: relative;
    margin-top: 15px;
    width: 80px;
    margin-left: 30px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elHoras > * { 
  width: 80px;
  }
  
}
@media (min-width: 1024px) {
  .HiceUnFavorScreen > .layoutFlow > .elHoras {
    position: relative;
    margin-top: 15px;
    width: 80px;
    margin-left: 30px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elHoras > * { 
  width: 80px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elText3 {
    overflow: hidden;
    position: absolute;
    margin-top: -23px;
    width: 30px;
    margin-left: 120px;
  }
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elText3 > * { 
  width: 30px;
  }
  
  .flowRow_HiceUnFavorScreen_elText3_868397 { 
  }
  
@media (min-width: 568px) {
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elText3 {
    overflow: hidden;
    position: absolute;
    margin-top: -23px;
    width: 30px;
    margin-left: 120px;
  }
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elText3 > * { 
  width: 30px;
  }
  
  .flowRow_HiceUnFavorScreen_elText3_868397 { 
  }
  
}
@media (min-width: 768px) {
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elText3 {
    overflow: hidden;
    position: absolute;
    margin-top: -23px;
    width: 30px;
    margin-left: 120px;
  }
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elText3 > * { 
  width: 30px;
  }
  
  .flowRow_HiceUnFavorScreen_elText3_868397 { 
  }
  
}
@media (min-width: 1024px) {
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elText3 {
    overflow: hidden;
    position: absolute;
    margin-top: -23px;
    width: 30px;
    margin-left: 120px;
  }
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elText3 > * { 
  width: 30px;
  }
  
  .flowRow_HiceUnFavorScreen_elText3_868397 { 
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HiceUnFavorScreen > .layoutFlow > .elMinutos {
    position: relative;
    margin-top: -27px;
    width: 80px;
    margin-left: 160px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elMinutos > * { 
  width: 80px;
  }
  
@media (min-width: 568px) {
  .HiceUnFavorScreen > .layoutFlow > .elMinutos {
    position: relative;
    margin-top: -27px;
    width: 80px;
    margin-left: 160px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elMinutos > * { 
  width: 80px;
  }
  
}
@media (min-width: 768px) {
  .HiceUnFavorScreen > .layoutFlow > .elMinutos {
    position: relative;
    margin-top: -27px;
    width: 80px;
    margin-left: 159px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elMinutos > * { 
  width: 80px;
  }
  
}
@media (min-width: 1024px) {
  .HiceUnFavorScreen > .layoutFlow > .elMinutos {
    position: relative;
    margin-top: -27px;
    width: 80px;
    margin-left: 159px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elMinutos > * { 
  width: 80px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elTextCopy {
    overflow: hidden;
    position: absolute;
    margin-top: -25px;
    width: 30px;
    margin-left: 250px;
  }
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elTextCopy > * { 
  width: 30px;
  }
  
  .flowRow_HiceUnFavorScreen_elTextCopy_128296 { 
  }
  
@media (min-width: 568px) {
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elTextCopy {
    overflow: hidden;
    position: absolute;
    margin-top: -25px;
    width: 30px;
    margin-left: 250px;
  }
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elTextCopy > * { 
  width: 30px;
  }
  
  .flowRow_HiceUnFavorScreen_elTextCopy_128296 { 
  }
  
}
@media (min-width: 768px) {
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elTextCopy {
    overflow: hidden;
    position: absolute;
    margin-top: -25px;
    width: 30px;
    margin-left: 248px;
  }
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elTextCopy > * { 
  width: 30px;
  }
  
  .flowRow_HiceUnFavorScreen_elTextCopy_128296 { 
  }
  
}
@media (min-width: 1024px) {
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elTextCopy {
    overflow: hidden;
    position: absolute;
    margin-top: -25px;
    width: 30px;
    margin-left: 248px;
  }
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elTextCopy > * { 
  width: 30px;
  }
  
  .flowRow_HiceUnFavorScreen_elTextCopy_128296 { 
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HiceUnFavorScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 7px;
    width: 10px;
    margin-left: 120px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .HiceUnFavorScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 7px;
    width: 10px;
    margin-left: 120px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .HiceUnFavorScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 7px;
    width: 10px;
    margin-left: 120px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .HiceUnFavorScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 7px;
    width: 10px;
    margin-left: 120px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HiceUnFavorScreen > .layoutFlow > .elText6 {
    line-height: 19px;
    position: relative;
    margin-top: 6px;
    width: 180px;
    margin-left: 48px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elText6 > * { 
  width: 180px;
  }
  
@media (min-width: 568px) {
  .HiceUnFavorScreen > .layoutFlow > .elText6 {
    line-height: 19px;
    position: relative;
    margin-top: 6px;
    width: 180px;
    margin-left: 48px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elText6 > * { 
  width: 180px;
  }
  
}
@media (min-width: 768px) {
  .HiceUnFavorScreen > .layoutFlow > .elText6 {
    line-height: 19px;
    position: relative;
    margin-top: 6px;
    width: 179px;
    margin-left: 47px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elText6 > * { 
  width: 179px;
  }
  
}
@media (min-width: 1024px) {
  .HiceUnFavorScreen > .layoutFlow > .elText6 {
    line-height: 19px;
    position: relative;
    margin-top: 6px;
    width: 179px;
    margin-left: 47px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elText6 > * { 
  width: 179px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elEducation_hours_added {
    line-height: 19px;
    position: absolute;
    margin-top: -18px;
    width: 65px;
    margin-left: 235px;
  }
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elEducation_hours_added > * { 
  width: 65px;
  }
  
  .flowRow_HiceUnFavorScreen_elEducation_hours_added_749983 { 
  }
  
@media (min-width: 568px) {
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elEducation_hours_added {
    line-height: 19px;
    position: absolute;
    margin-top: -18px;
    width: 65px;
    margin-left: 235px;
  }
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elEducation_hours_added > * { 
  width: 65px;
  }
  
  .flowRow_HiceUnFavorScreen_elEducation_hours_added_749983 { 
  }
  
}
@media (min-width: 768px) {
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elEducation_hours_added {
    line-height: 19px;
    position: absolute;
    margin-top: -18px;
    width: 64px;
    margin-left: 234px;
  }
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elEducation_hours_added > * { 
  width: 64px;
  }
  
  .flowRow_HiceUnFavorScreen_elEducation_hours_added_749983 { 
  }
  
}
@media (min-width: 1024px) {
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elEducation_hours_added {
    line-height: 19px;
    position: absolute;
    margin-top: -18px;
    width: 64px;
    margin-left: 234px;
  }
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elEducation_hours_added > * { 
  width: 64px;
  }
  
  .flowRow_HiceUnFavorScreen_elEducation_hours_added_749983 { 
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HiceUnFavorScreen > .layoutFlow > .elText7 {
    line-height: 19px;
    position: relative;
    margin-top: 10px;
    width: 198px;
    margin-left: 30px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elText7 > * { 
  width: 198px;
  }
  
@media (min-width: 568px) {
  .HiceUnFavorScreen > .layoutFlow > .elText7 {
    line-height: 19px;
    position: relative;
    margin-top: 10px;
    width: 198px;
    margin-left: 30px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elText7 > * { 
  width: 198px;
  }
  
}
@media (min-width: 768px) {
  .HiceUnFavorScreen > .layoutFlow > .elText7 {
    line-height: 19px;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: 30px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elText7 > * { 
  width: 197px;
  }
  
}
@media (min-width: 1024px) {
  .HiceUnFavorScreen > .layoutFlow > .elText7 {
    line-height: 19px;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: 30px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elText7 > * { 
  width: 197px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elExpiration_hours_added {
    line-height: 19px;
    position: absolute;
    margin-top: -18px;
    width: 65px;
    margin-left: 235px;
  }
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elExpiration_hours_added > * { 
  width: 65px;
  }
  
  .flowRow_HiceUnFavorScreen_elExpiration_hours_added_308310 { 
  }
  
@media (min-width: 568px) {
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elExpiration_hours_added {
    line-height: 19px;
    position: absolute;
    margin-top: -18px;
    width: 65px;
    margin-left: 235px;
  }
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elExpiration_hours_added > * { 
  width: 65px;
  }
  
  .flowRow_HiceUnFavorScreen_elExpiration_hours_added_308310 { 
  }
  
}
@media (min-width: 768px) {
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elExpiration_hours_added {
    line-height: 19px;
    position: absolute;
    margin-top: -18px;
    width: 64px;
    margin-left: 234px;
  }
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elExpiration_hours_added > * { 
  width: 64px;
  }
  
  .flowRow_HiceUnFavorScreen_elExpiration_hours_added_308310 { 
  }
  
}
@media (min-width: 1024px) {
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elExpiration_hours_added {
    line-height: 19px;
    position: absolute;
    margin-top: -18px;
    width: 64px;
    margin-left: 234px;
  }
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elExpiration_hours_added > * { 
  width: 64px;
  }
  
  .flowRow_HiceUnFavorScreen_elExpiration_hours_added_308310 { 
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HiceUnFavorScreen > .layoutFlow > .elText8 {
    line-height: 19px;
    position: relative;
    margin-top: 10px;
    width: 118px;
    margin-left: 110px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elText8 > * { 
  width: 118px;
  }
  
@media (min-width: 568px) {
  .HiceUnFavorScreen > .layoutFlow > .elText8 {
    line-height: 19px;
    position: relative;
    margin-top: 10px;
    width: 118px;
    margin-left: 110px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elText8 > * { 
  width: 118px;
  }
  
}
@media (min-width: 768px) {
  .HiceUnFavorScreen > .layoutFlow > .elText8 {
    line-height: 19px;
    position: relative;
    margin-top: 9px;
    width: 117px;
    margin-left: 110px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elText8 > * { 
  width: 117px;
  }
  
}
@media (min-width: 1024px) {
  .HiceUnFavorScreen > .layoutFlow > .elText8 {
    line-height: 19px;
    position: relative;
    margin-top: 9px;
    width: 117px;
    margin-left: 110px;
  }
  .HiceUnFavorScreen > .layoutFlow > .elText8 > * { 
  width: 117px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elTotal_hours {
    line-height: 19px;
    position: absolute;
    margin-top: -18px;
    width: 65px;
    margin-left: 235px;
  }
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elTotal_hours > * { 
  width: 65px;
  }
  
  .flowRow_HiceUnFavorScreen_elTotal_hours_301904 { 
  }
  
@media (min-width: 568px) {
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elTotal_hours {
    line-height: 19px;
    position: absolute;
    margin-top: -18px;
    width: 65px;
    margin-left: 235px;
  }
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elTotal_hours > * { 
  width: 65px;
  }
  
  .flowRow_HiceUnFavorScreen_elTotal_hours_301904 { 
  }
  
}
@media (min-width: 768px) {
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elTotal_hours {
    line-height: 19px;
    position: absolute;
    margin-top: -18px;
    width: 64px;
    margin-left: 234px;
  }
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elTotal_hours > * { 
  width: 64px;
  }
  
  .flowRow_HiceUnFavorScreen_elTotal_hours_301904 { 
  }
  
}
@media (min-width: 1024px) {
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elTotal_hours {
    line-height: 19px;
    position: absolute;
    margin-top: -18px;
    width: 64px;
    margin-left: 234px;
  }
  .HiceUnFavorScreen > .layoutFlow > .flowRow > .elTotal_hours > * { 
  width: 64px;
  }
  
  .flowRow_HiceUnFavorScreen_elTotal_hours_301904 { 
  }
  
}

.HiceUnFavorScreen .elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.HiceUnFavorScreen .elButton button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .HiceUnFavorScreen > .layoutFlow > .elButton {
    line-height: 49px;
    position: relative;
    margin-top: 27px;
    width: 210px;
    margin-left: calc(50.0% + -105px);
  }
  .HiceUnFavorScreen > .layoutFlow > .elButton > * { 
  width: 210px;
  height: 49px;
  }
  
@media (min-width: 568px) {
  .HiceUnFavorScreen > .layoutFlow > .elButton {
    line-height: 49px;
    position: relative;
    margin-top: 27px;
    width: 210px;
    margin-left: calc(50.0% + -105px);
  }
  .HiceUnFavorScreen > .layoutFlow > .elButton > * { 
  width: 210px;
  height: 49px;
  }
  
}
@media (min-width: 768px) {
  .HiceUnFavorScreen > .layoutFlow > .elButton {
    line-height: 49px;
    position: relative;
    margin-top: 27px;
    width: 209px;
    margin-left: calc(50.0% + -104px);
  }
  .HiceUnFavorScreen > .layoutFlow > .elButton > * { 
  width: 209px;
  height: 49px;
  }
  
}
@media (min-width: 1024px) {
  .HiceUnFavorScreen > .layoutFlow > .elButton {
    line-height: 49px;
    position: relative;
    margin-top: 27px;
    width: 209px;
    margin-left: calc(50.0% + -104px);
  }
  .HiceUnFavorScreen > .layoutFlow > .elButton > * { 
  width: 209px;
  height: 49px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HiceUnFavorScreen > .screenFgContainer > .foreground > .elMomentJS {
    position: absolute;
    left: 0px;
    top: 523px;
    width: 23px;
    height: 23px;
  }
@media (min-width: 568px) {
  .HiceUnFavorScreen > .screenFgContainer > .foreground > .elMomentJS {
    left: 0px;
    top: 523px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 768px) {
  .HiceUnFavorScreen > .screenFgContainer > .foreground > .elMomentJS {
    left: 0px;
    top: 520px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 1024px) {
  .HiceUnFavorScreen > .screenFgContainer > .foreground > .elMomentJS {
    left: 0px;
    top: 520px;
    width: 23px;
    height: 23px;
  }
}


/* Narrowest size for this element's responsive layout */
  .HiceUnFavorScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    position: absolute;
    left: 0px;
    top: 523px;
    width: 23px;
    height: 23px;
  }
@media (min-width: 568px) {
  .HiceUnFavorScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    left: 0px;
    top: 523px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 768px) {
  .HiceUnFavorScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    left: 0px;
    top: 520px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 1024px) {
  .HiceUnFavorScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    left: 0px;
    top: 520px;
    width: 23px;
    height: 23px;
  }
}


/* Narrowest size for this element's responsive layout */
  .HiceUnFavorScreen > .screenFgContainer > .foreground > .elTweetnacl {
    position: absolute;
    left: 280px;
    top: 560px;
    width: 23px;
    height: 23px;
  }
@media (min-width: 568px) {
  .HiceUnFavorScreen > .screenFgContainer > .foreground > .elTweetnacl {
    left: 280px;
    top: 560px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 768px) {
  .HiceUnFavorScreen > .screenFgContainer > .foreground > .elTweetnacl {
    left: 278px;
    top: 556px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 1024px) {
  .HiceUnFavorScreen > .screenFgContainer > .foreground > .elTweetnacl {
    left: 278px;
    top: 556px;
    width: 23px;
    height: 23px;
  }
}

/* This component has a fixed-size layout */
  .FavoresRealizados {
    width: 100%; /* This component was designed using a width of 380px */
    height: 100%;
  }


  .FavoresRealizados > .layoutFlow > .flowRow > .elText5 {
    line-height: 19px;
    position: absolute;
    margin-top: 4px;
    width: 100%;
  }
  .FavoresRealizados > .layoutFlow > .flowRow > .elText5 > * { 
  margin-left: auto;
  width: 238px;
  margin-right: 4px;
  }
  
  .flowRow_FavoresRealizados_elText5_193989 { 
  }
  


  .FavoresRealizados > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 15px;
    width: 85px;
    margin-left: calc(0.7% + 9px);
  }
  .FavoresRealizados > .layoutFlow > .elText2 > * { 
  width: 85px;
  }
  


  .FavoresRealizados > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: -54px;
    width: 238px;
    margin-left: 109px;
  }
  .FavoresRealizados > .layoutFlow > .elText > * { 
  width: 238px;
  }
  


  .FavoresRealizados > .layoutFlow > .elText4 {
    line-height: 19px;
    position: relative;
    margin-top: 0px;
    margin-left: 109px;
    margin-right: 8px;
    width: calc(100.0% - 117px);
  }
  .FavoresRealizados > .layoutFlow > .elText4 > * { 
    width: 100%;
  }
  


  .FavoresRealizados > .layoutFlow > .elText3 {
    overflow: hidden;
    position: relative;
    margin-top: 4px;
    width: 85px;
    margin-left: calc(0.7% + 9px);
  }
  .FavoresRealizados > .layoutFlow > .elText3 > * { 
  width: 85px;
  }
  


  .FavoresRealizados > .layoutFlow > .elTextCopy {
    overflow: hidden;
    position: relative;
    margin-top: -36px;
    width: 238px;
    margin-left: 107px;
  }
  .FavoresRealizados > .layoutFlow > .elTextCopy > * { 
  width: 238px;
  }
  


  .FavoresRealizados > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 21px;
    width: 10px;
    margin-left: 140px;
  }
  .FavoresRealizados > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  

/* This component has a fixed-size layout */
  .FavoresRecibidos {
    width: 100%; /* This component was designed using a width of 356px */
    height: 100%;
  }


  .FavoresRecibidos > .layoutFlow > .flowRow > .elText3 {
    line-height: 19px;
    position: absolute;
    margin-top: 4px;
    width: 100%;
  }
  .FavoresRecibidos > .layoutFlow > .flowRow > .elText3 > * { 
  margin-left: auto;
  width: 238px;
  margin-right: 4px;
  }
  
  .flowRow_FavoresRecibidos_elText3_243140 { 
  }
  


  .FavoresRecibidos > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 14px;
    width: 90px;
    margin-left: 9px;
  }
  .FavoresRecibidos > .layoutFlow > .elText > * { 
  width: 90px;
  }
  


  .FavoresRecibidos > .layoutFlow > .elText2 {
    line-height: 24px;
    position: relative;
    margin-top: -50px;
    width: 238px;
    margin-left: 109px;
  }
  .FavoresRecibidos > .layoutFlow > .elText2 > * { 
  width: 238px;
  }
  


  .FavoresRecibidos > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 12px;
    width: 10px;
    margin-left: 130px;
  }
  .FavoresRecibidos > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  


/* Narrowest size for this element's responsive layout */
  .PresentarParaFirmaScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .PresentarParaFirmaScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .PresentarParaFirmaScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .PresentarParaFirmaScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .PresentarParaFirmaScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .PresentarParaFirmaScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .PresentarParaFirmaScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .PresentarParaFirmaScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .PresentarParaFirmaScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .PresentarParaFirmaScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .PresentarParaFirmaScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .PresentarParaFirmaScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PresentarParaFirmaScreen > .screenFgContainer > .foreground > .elQrwriter {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 79px;
    width: 100.0%;
    height: calc(100% + 0.0% + -235.0px);
  }
@media (min-width: 568px) {
  .PresentarParaFirmaScreen > .screenFgContainer > .foreground > .elQrwriter {
    left: 0px;
    right: 0px;
    top: 79px;
    width: 100.0%;
    height: calc(100% + 0.0% + -90.0px);
  }
}
@media (min-width: 768px) {
  .PresentarParaFirmaScreen > .screenFgContainer > .foreground > .elQrwriter {
    left: 0px;
    right: 0px;
    top: 78px;
    width: 100.0%;
    height: calc(100% + 0.0% + -233.5px);
  }
}
@media (min-width: 1024px) {
  .PresentarParaFirmaScreen > .screenFgContainer > .foreground > .elQrwriter {
    left: 0px;
    right: 0px;
    top: 78px;
    width: 100.0%;
    height: calc(100% + 0.0% + -233.5px);
  }
}


/* Narrowest size for this element's responsive layout */
  .FirmarScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .FirmarScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .FirmarScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .FirmarScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.FirmarScreen .elComp {
}

.FirmarScreen .elComp.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .FirmarScreen > .layoutFlow > .elComp {
    position: relative;
    margin-top: 62px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .FirmarScreen > .layoutFlow > .elComp > * { 
    width: 100%;
  }
  
  .FirmarScreen > .layoutFlow > .elComp > .background > .containerMinHeight {
    min-height: 273px;
  }

@media (min-width: 568px) {
  .FirmarScreen > .layoutFlow > .elComp {
    position: relative;
    margin-top: 62px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .FirmarScreen > .layoutFlow > .elComp > * { 
    width: 100%;
  }
  
  .FirmarScreen > .layoutFlow > .elComp > .background > .containerMinHeight {
    min-height: 273px;
  }

}
@media (min-width: 768px) {
  .FirmarScreen > .layoutFlow > .elComp {
    position: relative;
    margin-top: 62px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .FirmarScreen > .layoutFlow > .elComp > * { 
    width: 100%;
  }
  
  .FirmarScreen > .layoutFlow > .elComp > .background > .containerMinHeight {
    min-height: 273px;
  }

}
@media (min-width: 1024px) {
  .FirmarScreen > .layoutFlow > .elComp {
    position: relative;
    margin-top: 62px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .FirmarScreen > .layoutFlow > .elComp > * { 
    width: 100%;
  }
  
  .FirmarScreen > .layoutFlow > .elComp > .background > .containerMinHeight {
    min-height: 273px;
  }

}

.FirmarScreen .elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.FirmarScreen .elButton button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .FirmarScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 14px;
    width: 145px;
    margin-left: calc(50.0% + 8px);
  }
  .FirmarScreen > .layoutFlow > .elButton > * { 
  width: 145px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .FirmarScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 14px;
    width: 145px;
    margin-left: calc(50.0% + 8px);
  }
  .FirmarScreen > .layoutFlow > .elButton > * { 
  width: 145px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .FirmarScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 14px;
    width: 144px;
    margin-left: calc(50.0% + 8px);
  }
  .FirmarScreen > .layoutFlow > .elButton > * { 
  width: 144px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .FirmarScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 14px;
    width: 144px;
    margin-left: calc(50.0% + 8px);
  }
  .FirmarScreen > .layoutFlow > .elButton > * { 
  width: 144px;
  height: 35px;
  }
  
}

.FirmarScreen .elButton2 button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.FirmarScreen .elButton2 button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .FirmarScreen > .layoutFlow > .elButton2 {
    line-height: 31px;
    position: relative;
    margin-top: -34px;
    width: 143px;
    margin-left: calc(50.0% + -153px);
  }
  .FirmarScreen > .layoutFlow > .elButton2 > * { 
  width: 143px;
  height: 31px;
  }
  
@media (min-width: 568px) {
  .FirmarScreen > .layoutFlow > .elButton2 {
    line-height: 31px;
    position: relative;
    margin-top: -34px;
    width: 143px;
    margin-left: calc(50.0% + -153px);
  }
  .FirmarScreen > .layoutFlow > .elButton2 > * { 
  width: 143px;
  height: 31px;
  }
  
}
@media (min-width: 768px) {
  .FirmarScreen > .layoutFlow > .elButton2 {
    line-height: 31px;
    position: relative;
    margin-top: -34px;
    width: 142px;
    margin-left: calc(50.0% + -152px);
  }
  .FirmarScreen > .layoutFlow > .elButton2 > * { 
  width: 142px;
  height: 31px;
  }
  
}
@media (min-width: 1024px) {
  .FirmarScreen > .layoutFlow > .elButton2 {
    line-height: 31px;
    position: relative;
    margin-top: -34px;
    width: 142px;
    margin-left: calc(50.0% + -152px);
  }
  .FirmarScreen > .layoutFlow > .elButton2 > * { 
  width: 142px;
  height: 31px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .FirmarScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    position: absolute;
    left: 30px;
    top: 570px;
    width: 23px;
    height: 23px;
  }
@media (min-width: 568px) {
  .FirmarScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    left: 30px;
    top: 570px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 768px) {
  .FirmarScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    left: 30px;
    top: 566px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 1024px) {
  .FirmarScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    left: 30px;
    top: 566px;
    width: 23px;
    height: 23px;
  }
}


/* Narrowest size for this element's responsive layout */
  .FirmarScreen > .screenFgContainer > .foreground > .elTweetnacl {
    position: absolute;
    left: 220px;
    top: 561px;
    width: 23px;
    height: 23px;
  }
@media (min-width: 568px) {
  .FirmarScreen > .screenFgContainer > .foreground > .elTweetnacl {
    left: 220px;
    top: 561px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 768px) {
  .FirmarScreen > .screenFgContainer > .foreground > .elTweetnacl {
    left: 219px;
    top: 558px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 1024px) {
  .FirmarScreen > .screenFgContainer > .foreground > .elTweetnacl {
    left: 219px;
    top: 558px;
    width: 23px;
    height: 23px;
  }
}


/* Narrowest size for this element's responsive layout */
  .FirmarScreen > .screenFgContainer > .foreground > .elTweetnacl2 {
    position: absolute;
    left: 264px;
    top: 568px;
    width: 23px;
    height: 23px;
  }
@media (min-width: 568px) {
  .FirmarScreen > .screenFgContainer > .foreground > .elTweetnacl2 {
    left: 264px;
    top: 568px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 768px) {
  .FirmarScreen > .screenFgContainer > .foreground > .elTweetnacl2 {
    left: 262px;
    top: 564px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 1024px) {
  .FirmarScreen > .screenFgContainer > .foreground > .elTweetnacl2 {
    left: 262px;
    top: 564px;
    width: 23px;
    height: 23px;
  }
}

/* This component has a fixed-size layout */
  .Comp2 {
    min-width: 399px; /* This component has a fixed size */
  }


  .Comp2 > .layoutFlow > .elCard {
    position: relative;
    margin-top: 0px;
    width: 272px;
    margin-left: 0px;
  }
  .Comp2 > .layoutFlow > .elCard > * { 
  width: 272px;
  height: 204px;
  }
  


  .Comp2 > .layoutFlow > .elText {
    line-height: 19px;
    position: relative;
    margin-top: -194px;
    width: 238px;
    margin-left: 160px;
  }
  .Comp2 > .layoutFlow > .elText > * { 
  width: 238px;
  }
  


  .Comp2 > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    width: 238px;
    margin-left: 40px;
  }
  .Comp2 > .layoutFlow > .elText2 > * { 
  width: 238px;
  }
  

/* This component has a fixed-size layout */
  .Comp4 {
    width: 100%; /* This component was designed using a width of 348px */
    height: 100%;
  }


  .Comp4 > .layoutFlow > .elText4 {
    overflow: hidden;
    position: relative;
    margin-top: 16px;
    width: 30px;
    margin-left: 6.9%;
  }
  .Comp4 > .layoutFlow > .elText4 > * { 
  width: 30px;
  }
  


  .Comp4 > .layoutFlow > .elDynamic_received {
    line-height: 19px;
    position: relative;
    margin-top: -21px;
    width: 50px;
    margin-left: calc(6.9% + 30px);
  }
  .Comp4 > .layoutFlow > .elDynamic_received > * { 
  width: 50px;
  }
  


  .Comp4 > .layoutFlow > .elText3 {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 74px;
    margin-left: 6.9%;
  }
  .Comp4 > .layoutFlow > .elText3 > * { 
  width: 74px;
  }
  


  .Comp4 > .layoutFlow > .elDynamic_given {
    line-height: 19px;
    position: relative;
    margin-top: -20px;
    width: 248px;
    margin-left: calc(6.9% + 68px);
  }
  .Comp4 > .layoutFlow > .elDynamic_given > * { 
  width: 248px;
  }
  


  .Comp4 > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 94px;
    margin-left: 6.9%;
  }
  .Comp4 > .layoutFlow > .elText > * { 
  width: 94px;
  }
  


  .Comp4 > .layoutFlow > .elDynamic_hours {
    line-height: 19px;
    position: relative;
    margin-top: -20px;
    width: 40px;
    margin-left: calc(6.9% + 91px);
  }
  .Comp4 > .layoutFlow > .elDynamic_hours > * { 
  width: 40px;
  }
  


  .Comp4 > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: -20px;
    width: 144px;
    margin-left: calc(6.9% + 131px);
  }
  .Comp4 > .layoutFlow > .elText2 > * { 
  width: 144px;
  }
  


  .Comp4 > .layoutFlow > .elText5 {
    line-height: 18px;
    position: relative;
    margin-top: 7px;
    width: 238px;
    margin-left: 24px;
  }
  .Comp4 > .layoutFlow > .elText5 > * { 
  width: 238px;
  }
  

.Comp4 .elList li {
  margin-bottom: 5px;
}



  .Comp4 > .layoutFlow > .elList {
    position: relative;
    margin-top: 17px;
    margin-left: 10px;
    margin-right: 11px;
    width: calc(100.0% - 21px);
  }
  .Comp4 > .layoutFlow > .elList > * { 
    width: 100%;
  }
  


  .Comp4 > .layoutFlow > .elTextBlock {
    line-height: 19px;
    position: relative;
    margin-top: 10px;
    width: 246px;
    margin-left: 6.9%;
  }
  .Comp4 > .layoutFlow > .elTextBlock > * { 
  width: 246px;
  }
  


  .Comp4 > .layoutFlow > .elSpacer3 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 130px;
  }
  .Comp4 > .layoutFlow > .elSpacer3 > * { 
  width: 10px;
  height: 10px;
  }
  


/* Narrowest size for this element's responsive layout */
  .PerfilScreen > .background > .state0_elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .PerfilScreen > .background > .state0_elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .PerfilScreen > .background > .state0_elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .PerfilScreen > .background > .state0_elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .PerfilScreen > .layoutFlow > .state0_elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .PerfilScreen > .layoutFlow > .state0_elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .PerfilScreen > .layoutFlow > .state0_elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .PerfilScreen > .layoutFlow > .state0_elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .PerfilScreen > .layoutFlow > .state0_elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .PerfilScreen > .layoutFlow > .state0_elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .PerfilScreen > .layoutFlow > .state0_elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .PerfilScreen > .layoutFlow > .state0_elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}

.PerfilScreen .state0_elFab222580 button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.PerfilScreen .state0_elFab222580 button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .PerfilScreen > .layoutFlow > .flowRow > .state0_elFab222580 {
    line-height: 46px;
    position: absolute;
    margin-top: 12px;
    width: 100%;
  }
  .PerfilScreen > .layoutFlow > .flowRow > .state0_elFab222580 > * { 
  margin-left: auto;
  width: 47px;
  margin-right: 11px;
  height: 46px;
  }
  
  .flowRow_PerfilScreen_state0_elFab222580_222580 { 
  }
  
@media (min-width: 568px) {
  .PerfilScreen > .layoutFlow > .flowRow > .state0_elFab222580 {
    line-height: 46px;
    position: absolute;
    margin-top: 12px;
    width: 100%;
  }
  .PerfilScreen > .layoutFlow > .flowRow > .state0_elFab222580 > * { 
  margin-left: auto;
  width: 47px;
  margin-right: 11px;
  height: 46px;
  }
  
  .flowRow_PerfilScreen_state0_elFab222580_222580 { 
  }
  
}
@media (min-width: 768px) {
  .PerfilScreen > .layoutFlow > .flowRow > .state0_elFab222580 {
    line-height: 46px;
    position: absolute;
    margin-top: 12px;
    width: 100%;
  }
  .PerfilScreen > .layoutFlow > .flowRow > .state0_elFab222580 > * { 
  margin-left: auto;
  width: 47px;
  margin-right: 11px;
  height: 46px;
  }
  
  .flowRow_PerfilScreen_state0_elFab222580_222580 { 
  }
  
}
@media (min-width: 1024px) {
  .PerfilScreen > .layoutFlow > .flowRow > .state0_elFab222580 {
    line-height: 46px;
    position: absolute;
    margin-top: 12px;
    width: 100%;
  }
  .PerfilScreen > .layoutFlow > .flowRow > .state0_elFab222580 > * { 
  margin-left: auto;
  width: 47px;
  margin-right: 11px;
  height: 46px;
  }
  
  .flowRow_PerfilScreen_state0_elFab222580_222580 { 
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PerfilScreen > .layoutFlow > .state0_elName {
    line-height: 24px;
    position: relative;
    margin-top: 9px;
    margin-left: 15px;
    margin-right: 20px;
    width: calc(100.0% - 35px);
  }
  .PerfilScreen > .layoutFlow > .state0_elName > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PerfilScreen > .layoutFlow > .state0_elName {
    line-height: 24px;
    position: relative;
    margin-top: 9px;
    margin-left: 15px;
    margin-right: 20px;
    width: calc(100.0% - 35px);
  }
  .PerfilScreen > .layoutFlow > .state0_elName > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PerfilScreen > .layoutFlow > .state0_elName {
    line-height: 24px;
    position: relative;
    margin-top: 9px;
    margin-left: 15px;
    margin-right: 20px;
    width: calc(100.0% - 35px);
  }
  .PerfilScreen > .layoutFlow > .state0_elName > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PerfilScreen > .layoutFlow > .state0_elName {
    line-height: 24px;
    position: relative;
    margin-top: 9px;
    margin-left: 15px;
    margin-right: 20px;
    width: calc(100.0% - 35px);
  }
  .PerfilScreen > .layoutFlow > .state0_elName > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PerfilScreen > .layoutFlow > .state0_elHabilidadesLabel {
    line-height: 24px;
    position: relative;
    margin-top: 5px;
    width: 238px;
    margin-left: 12px;
  }
  .PerfilScreen > .layoutFlow > .state0_elHabilidadesLabel > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .PerfilScreen > .layoutFlow > .state0_elHabilidadesLabel {
    line-height: 24px;
    position: relative;
    margin-top: 5px;
    width: 238px;
    margin-left: 12px;
  }
  .PerfilScreen > .layoutFlow > .state0_elHabilidadesLabel > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .PerfilScreen > .layoutFlow > .state0_elHabilidadesLabel {
    line-height: 24px;
    position: relative;
    margin-top: 5px;
    width: 237px;
    margin-left: 12px;
  }
  .PerfilScreen > .layoutFlow > .state0_elHabilidadesLabel > * { 
  width: 237px;
  }
  
}
@media (min-width: 1024px) {
  .PerfilScreen > .layoutFlow > .state0_elHabilidadesLabel {
    line-height: 24px;
    position: relative;
    margin-top: 5px;
    width: 237px;
    margin-left: 12px;
  }
  .PerfilScreen > .layoutFlow > .state0_elHabilidadesLabel > * { 
  width: 237px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PerfilScreen > .layoutFlow > .state0_elHabilidadesContent {
    line-height: 19px;
    position: relative;
    margin-top: 4px;
    margin-left: 12px;
    margin-right: 15px;
    width: calc(100.0% - 26px);
  }
  .PerfilScreen > .layoutFlow > .state0_elHabilidadesContent > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PerfilScreen > .layoutFlow > .state0_elHabilidadesContent {
    line-height: 19px;
    position: relative;
    margin-top: 4px;
    margin-left: 12px;
    margin-right: 15px;
    width: calc(100.0% - 26px);
  }
  .PerfilScreen > .layoutFlow > .state0_elHabilidadesContent > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PerfilScreen > .layoutFlow > .state0_elHabilidadesContent {
    line-height: 19px;
    position: relative;
    margin-top: 4px;
    margin-left: 12px;
    margin-right: 15px;
    width: calc(100.0% - 26px);
  }
  .PerfilScreen > .layoutFlow > .state0_elHabilidadesContent > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PerfilScreen > .layoutFlow > .state0_elHabilidadesContent {
    line-height: 19px;
    position: relative;
    margin-top: 4px;
    margin-left: 12px;
    margin-right: 15px;
    width: calc(100.0% - 26px);
  }
  .PerfilScreen > .layoutFlow > .state0_elHabilidadesContent > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PerfilScreen > .layoutFlow > .state0_elRequestsLabel {
    line-height: 24px;
    position: relative;
    margin-top: 6px;
    width: 238px;
    margin-left: 10px;
  }
  .PerfilScreen > .layoutFlow > .state0_elRequestsLabel > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .PerfilScreen > .layoutFlow > .state0_elRequestsLabel {
    line-height: 24px;
    position: relative;
    margin-top: 6px;
    width: 238px;
    margin-left: 10px;
  }
  .PerfilScreen > .layoutFlow > .state0_elRequestsLabel > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .PerfilScreen > .layoutFlow > .state0_elRequestsLabel {
    line-height: 24px;
    position: relative;
    margin-top: 6px;
    width: 237px;
    margin-left: 10px;
  }
  .PerfilScreen > .layoutFlow > .state0_elRequestsLabel > * { 
  width: 237px;
  }
  
}
@media (min-width: 1024px) {
  .PerfilScreen > .layoutFlow > .state0_elRequestsLabel {
    line-height: 24px;
    position: relative;
    margin-top: 6px;
    width: 237px;
    margin-left: 10px;
  }
  .PerfilScreen > .layoutFlow > .state0_elRequestsLabel > * { 
  width: 237px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PerfilScreen > .layoutFlow > .state0_elRequestsContent {
    line-height: 19px;
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 10px;
  }
  .PerfilScreen > .layoutFlow > .state0_elRequestsContent > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .PerfilScreen > .layoutFlow > .state0_elRequestsContent {
    line-height: 19px;
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 10px;
  }
  .PerfilScreen > .layoutFlow > .state0_elRequestsContent > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .PerfilScreen > .layoutFlow > .state0_elRequestsContent {
    line-height: 19px;
    position: relative;
    margin-top: 0px;
    width: 237px;
    margin-left: 10px;
  }
  .PerfilScreen > .layoutFlow > .state0_elRequestsContent > * { 
  width: 237px;
  }
  
}
@media (min-width: 1024px) {
  .PerfilScreen > .layoutFlow > .state0_elRequestsContent {
    line-height: 19px;
    position: relative;
    margin-top: 0px;
    width: 237px;
    margin-left: 10px;
  }
  .PerfilScreen > .layoutFlow > .state0_elRequestsContent > * { 
  width: 237px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PerfilScreen > .layoutFlow > .state0_elPendingFavorsLabel {
    line-height: 19px;
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 10px;
  }
  .PerfilScreen > .layoutFlow > .state0_elPendingFavorsLabel > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .PerfilScreen > .layoutFlow > .state0_elPendingFavorsLabel {
    line-height: 19px;
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 10px;
  }
  .PerfilScreen > .layoutFlow > .state0_elPendingFavorsLabel > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .PerfilScreen > .layoutFlow > .state0_elPendingFavorsLabel {
    line-height: 19px;
    position: relative;
    margin-top: 0px;
    width: 237px;
    margin-left: 10px;
  }
  .PerfilScreen > .layoutFlow > .state0_elPendingFavorsLabel > * { 
  width: 237px;
  }
  
}
@media (min-width: 1024px) {
  .PerfilScreen > .layoutFlow > .state0_elPendingFavorsLabel {
    line-height: 19px;
    position: relative;
    margin-top: 0px;
    width: 237px;
    margin-left: 10px;
  }
  .PerfilScreen > .layoutFlow > .state0_elPendingFavorsLabel > * { 
  width: 237px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PerfilScreen > .layoutFlow > .state0_elPendingFavorsContent {
    line-height: 19px;
    position: relative;
    margin-top: 0px;
    margin-left: 10px;
    margin-right: 12px;
    width: calc(100.0% - 22px);
  }
  .PerfilScreen > .layoutFlow > .state0_elPendingFavorsContent > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PerfilScreen > .layoutFlow > .state0_elPendingFavorsContent {
    line-height: 19px;
    position: relative;
    margin-top: 0px;
    margin-left: 10px;
    margin-right: 12px;
    width: calc(100.0% - 22px);
  }
  .PerfilScreen > .layoutFlow > .state0_elPendingFavorsContent > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PerfilScreen > .layoutFlow > .state0_elPendingFavorsContent {
    line-height: 19px;
    position: relative;
    margin-top: 0px;
    margin-left: 10px;
    margin-right: 12px;
    width: calc(100.0% - 22px);
  }
  .PerfilScreen > .layoutFlow > .state0_elPendingFavorsContent > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PerfilScreen > .layoutFlow > .state0_elPendingFavorsContent {
    line-height: 19px;
    position: relative;
    margin-top: 0px;
    margin-left: 10px;
    margin-right: 12px;
    width: calc(100.0% - 22px);
  }
  .PerfilScreen > .layoutFlow > .state0_elPendingFavorsContent > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PerfilScreen > .layoutFlow > .state0_elFavorsGivenLabel {
    line-height: 24px;
    position: relative;
    margin-top: 14px;
    width: 238px;
    margin-left: 10px;
  }
  .PerfilScreen > .layoutFlow > .state0_elFavorsGivenLabel > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .PerfilScreen > .layoutFlow > .state0_elFavorsGivenLabel {
    line-height: 24px;
    position: relative;
    margin-top: 14px;
    width: 238px;
    margin-left: 10px;
  }
  .PerfilScreen > .layoutFlow > .state0_elFavorsGivenLabel > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .PerfilScreen > .layoutFlow > .state0_elFavorsGivenLabel {
    line-height: 24px;
    position: relative;
    margin-top: 14px;
    width: 237px;
    margin-left: 10px;
  }
  .PerfilScreen > .layoutFlow > .state0_elFavorsGivenLabel > * { 
  width: 237px;
  }
  
}
@media (min-width: 1024px) {
  .PerfilScreen > .layoutFlow > .state0_elFavorsGivenLabel {
    line-height: 24px;
    position: relative;
    margin-top: 14px;
    width: 237px;
    margin-left: 10px;
  }
  .PerfilScreen > .layoutFlow > .state0_elFavorsGivenLabel > * { 
  width: 237px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PerfilScreen > .layoutFlow > .state0_elFavorsGivenContent {
    line-height: 19px;
    position: relative;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 12px;
    width: calc(100.0% - 22px);
  }
  .PerfilScreen > .layoutFlow > .state0_elFavorsGivenContent > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PerfilScreen > .layoutFlow > .state0_elFavorsGivenContent {
    line-height: 19px;
    position: relative;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 12px;
    width: calc(100.0% - 22px);
  }
  .PerfilScreen > .layoutFlow > .state0_elFavorsGivenContent > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PerfilScreen > .layoutFlow > .state0_elFavorsGivenContent {
    line-height: 19px;
    position: relative;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 12px;
    width: calc(100.0% - 22px);
  }
  .PerfilScreen > .layoutFlow > .state0_elFavorsGivenContent > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PerfilScreen > .layoutFlow > .state0_elFavorsGivenContent {
    line-height: 19px;
    position: relative;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 12px;
    width: calc(100.0% - 22px);
  }
  .PerfilScreen > .layoutFlow > .state0_elFavorsGivenContent > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PerfilScreen > .layoutFlow > .state0_elPositiveBalanceContent {
    line-height: 19px;
    position: relative;
    margin-top: 6px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .PerfilScreen > .layoutFlow > .state0_elPositiveBalanceContent > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PerfilScreen > .layoutFlow > .state0_elPositiveBalanceContent {
    line-height: 19px;
    position: relative;
    margin-top: 6px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .PerfilScreen > .layoutFlow > .state0_elPositiveBalanceContent > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PerfilScreen > .layoutFlow > .state0_elPositiveBalanceContent {
    line-height: 19px;
    position: relative;
    margin-top: 6px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .PerfilScreen > .layoutFlow > .state0_elPositiveBalanceContent > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PerfilScreen > .layoutFlow > .state0_elPositiveBalanceContent {
    line-height: 19px;
    position: relative;
    margin-top: 6px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .PerfilScreen > .layoutFlow > .state0_elPositiveBalanceContent > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PerfilScreen > .layoutFlow > .state0_elFavorsReceivedLabel {
    line-height: 24px;
    position: relative;
    margin-top: 13px;
    width: 238px;
    margin-left: 10px;
  }
  .PerfilScreen > .layoutFlow > .state0_elFavorsReceivedLabel > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .PerfilScreen > .layoutFlow > .state0_elFavorsReceivedLabel {
    line-height: 24px;
    position: relative;
    margin-top: 13px;
    width: 238px;
    margin-left: 10px;
  }
  .PerfilScreen > .layoutFlow > .state0_elFavorsReceivedLabel > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .PerfilScreen > .layoutFlow > .state0_elFavorsReceivedLabel {
    line-height: 24px;
    position: relative;
    margin-top: 13px;
    width: 237px;
    margin-left: 10px;
  }
  .PerfilScreen > .layoutFlow > .state0_elFavorsReceivedLabel > * { 
  width: 237px;
  }
  
}
@media (min-width: 1024px) {
  .PerfilScreen > .layoutFlow > .state0_elFavorsReceivedLabel {
    line-height: 24px;
    position: relative;
    margin-top: 13px;
    width: 237px;
    margin-left: 10px;
  }
  .PerfilScreen > .layoutFlow > .state0_elFavorsReceivedLabel > * { 
  width: 237px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PerfilScreen > .layoutFlow > .state0_elFavorsReceivedContent {
    line-height: 19px;
    position: relative;
    margin-top: 0px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .PerfilScreen > .layoutFlow > .state0_elFavorsReceivedContent > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PerfilScreen > .layoutFlow > .state0_elFavorsReceivedContent {
    line-height: 19px;
    position: relative;
    margin-top: 0px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .PerfilScreen > .layoutFlow > .state0_elFavorsReceivedContent > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PerfilScreen > .layoutFlow > .state0_elFavorsReceivedContent {
    line-height: 19px;
    position: relative;
    margin-top: 0px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .PerfilScreen > .layoutFlow > .state0_elFavorsReceivedContent > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PerfilScreen > .layoutFlow > .state0_elFavorsReceivedContent {
    line-height: 19px;
    position: relative;
    margin-top: 0px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .PerfilScreen > .layoutFlow > .state0_elFavorsReceivedContent > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PerfilScreen > .layoutFlow > .state0_elNegativeBalanceContent {
    line-height: 19px;
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 10px;
  }
  .PerfilScreen > .layoutFlow > .state0_elNegativeBalanceContent > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .PerfilScreen > .layoutFlow > .state0_elNegativeBalanceContent {
    line-height: 19px;
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 10px;
  }
  .PerfilScreen > .layoutFlow > .state0_elNegativeBalanceContent > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .PerfilScreen > .layoutFlow > .state0_elNegativeBalanceContent {
    line-height: 19px;
    position: relative;
    margin-top: 0px;
    width: 237px;
    margin-left: 10px;
  }
  .PerfilScreen > .layoutFlow > .state0_elNegativeBalanceContent > * { 
  width: 237px;
  }
  
}
@media (min-width: 1024px) {
  .PerfilScreen > .layoutFlow > .state0_elNegativeBalanceContent {
    line-height: 19px;
    position: relative;
    margin-top: 0px;
    width: 237px;
    margin-left: 10px;
  }
  .PerfilScreen > .layoutFlow > .state0_elNegativeBalanceContent > * { 
  width: 237px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PerfilScreen > .layoutFlow > .state0_elPersonalizedFavorsOwedContent {
    line-height: 24px;
    position: relative;
    margin-top: 17px;
    margin-left: 10px;
    margin-right: 8px;
    width: calc(100.0% - 18px);
  }
  .PerfilScreen > .layoutFlow > .state0_elPersonalizedFavorsOwedContent > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PerfilScreen > .layoutFlow > .state0_elPersonalizedFavorsOwedContent {
    line-height: 24px;
    position: relative;
    margin-top: 17px;
    margin-left: 10px;
    margin-right: 8px;
    width: calc(100.0% - 18px);
  }
  .PerfilScreen > .layoutFlow > .state0_elPersonalizedFavorsOwedContent > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PerfilScreen > .layoutFlow > .state0_elPersonalizedFavorsOwedContent {
    line-height: 24px;
    position: relative;
    margin-top: 17px;
    margin-left: 10px;
    margin-right: 8px;
    width: calc(100.0% - 18px);
  }
  .PerfilScreen > .layoutFlow > .state0_elPersonalizedFavorsOwedContent > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PerfilScreen > .layoutFlow > .state0_elPersonalizedFavorsOwedContent {
    line-height: 24px;
    position: relative;
    margin-top: 17px;
    margin-left: 10px;
    margin-right: 8px;
    width: calc(100.0% - 18px);
  }
  .PerfilScreen > .layoutFlow > .state0_elPersonalizedFavorsOwedContent > * { 
    width: 100%;
  }
  
}

.PerfilScreen .state0_elRequestFavorButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.PerfilScreen .state0_elRequestFavorButton button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .PerfilScreen > .layoutFlow > .state0_elRequestFavorButton {
    line-height: 48px;
    position: relative;
    margin-top: 32px;
    width: 180px;
    margin-left: calc(50.0% + -90px);
  }
  .PerfilScreen > .layoutFlow > .state0_elRequestFavorButton > * { 
  width: 180px;
  height: 48px;
  }
  
@media (min-width: 568px) {
  .PerfilScreen > .layoutFlow > .state0_elRequestFavorButton {
    line-height: 48px;
    position: relative;
    margin-top: 32px;
    width: 180px;
    margin-left: calc(50.0% + -90px);
  }
  .PerfilScreen > .layoutFlow > .state0_elRequestFavorButton > * { 
  width: 180px;
  height: 48px;
  }
  
}
@media (min-width: 768px) {
  .PerfilScreen > .layoutFlow > .state0_elRequestFavorButton {
    line-height: 48px;
    position: relative;
    margin-top: 32px;
    width: 179px;
    margin-left: calc(50.0% + -89px);
  }
  .PerfilScreen > .layoutFlow > .state0_elRequestFavorButton > * { 
  width: 179px;
  height: 48px;
  }
  
}
@media (min-width: 1024px) {
  .PerfilScreen > .layoutFlow > .state0_elRequestFavorButton {
    line-height: 48px;
    position: relative;
    margin-top: 32px;
    width: 179px;
    margin-left: calc(50.0% + -89px);
  }
  .PerfilScreen > .layoutFlow > .state0_elRequestFavorButton > * { 
  width: 179px;
  height: 48px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PerfilScreen > .layoutFlow > .state0_elCommentsLabel {
    line-height: 19px;
    position: relative;
    margin-top: 28px;
    width: 238px;
    margin-left: 10px;
  }
  .PerfilScreen > .layoutFlow > .state0_elCommentsLabel > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .PerfilScreen > .layoutFlow > .state0_elCommentsLabel {
    line-height: 19px;
    position: relative;
    margin-top: 28px;
    width: 238px;
    margin-left: 10px;
  }
  .PerfilScreen > .layoutFlow > .state0_elCommentsLabel > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .PerfilScreen > .layoutFlow > .state0_elCommentsLabel {
    line-height: 19px;
    position: relative;
    margin-top: 28px;
    width: 237px;
    margin-left: 10px;
  }
  .PerfilScreen > .layoutFlow > .state0_elCommentsLabel > * { 
  width: 237px;
  }
  
}
@media (min-width: 1024px) {
  .PerfilScreen > .layoutFlow > .state0_elCommentsLabel {
    line-height: 19px;
    position: relative;
    margin-top: 28px;
    width: 237px;
    margin-left: 10px;
  }
  .PerfilScreen > .layoutFlow > .state0_elCommentsLabel > * { 
  width: 237px;
  }
  
}

.PerfilScreen .state0_elLeaveCommentButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.PerfilScreen .state0_elLeaveCommentButton button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .PerfilScreen > .layoutFlow > .state0_elLeaveCommentButton {
    line-height: 48px;
    position: relative;
    margin-top: 6px;
    width: 270px;
    margin-left: calc(50.0% + -135px);
  }
  .PerfilScreen > .layoutFlow > .state0_elLeaveCommentButton > * { 
  width: 270px;
  height: 48px;
  }
  
@media (min-width: 568px) {
  .PerfilScreen > .layoutFlow > .state0_elLeaveCommentButton {
    line-height: 48px;
    position: relative;
    margin-top: 6px;
    width: 270px;
    margin-left: calc(50.0% + -135px);
  }
  .PerfilScreen > .layoutFlow > .state0_elLeaveCommentButton > * { 
  width: 270px;
  height: 48px;
  }
  
}
@media (min-width: 768px) {
  .PerfilScreen > .layoutFlow > .state0_elLeaveCommentButton {
    line-height: 48px;
    position: relative;
    margin-top: 6px;
    width: 268px;
    margin-left: calc(50.0% + -134px);
  }
  .PerfilScreen > .layoutFlow > .state0_elLeaveCommentButton > * { 
  width: 268px;
  height: 48px;
  }
  
}
@media (min-width: 1024px) {
  .PerfilScreen > .layoutFlow > .state0_elLeaveCommentButton {
    line-height: 48px;
    position: relative;
    margin-top: 6px;
    width: 268px;
    margin-left: calc(50.0% + -134px);
  }
  .PerfilScreen > .layoutFlow > .state0_elLeaveCommentButton > * { 
  width: 268px;
  height: 48px;
  }
  
}

.PerfilScreen .state0_elSendMessageButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.PerfilScreen .state0_elSendMessageButton button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .PerfilScreen > .layoutFlow > .state0_elSendMessageButton {
    line-height: 48px;
    position: relative;
    margin-top: 34px;
    width: 180px;
    margin-left: calc(50.0% + -90px);
  }
  .PerfilScreen > .layoutFlow > .state0_elSendMessageButton > * { 
  width: 180px;
  height: 48px;
  }
  
@media (min-width: 568px) {
  .PerfilScreen > .layoutFlow > .state0_elSendMessageButton {
    line-height: 48px;
    position: relative;
    margin-top: 34px;
    width: 180px;
    margin-left: calc(50.0% + -90px);
  }
  .PerfilScreen > .layoutFlow > .state0_elSendMessageButton > * { 
  width: 180px;
  height: 48px;
  }
  
}
@media (min-width: 768px) {
  .PerfilScreen > .layoutFlow > .state0_elSendMessageButton {
    line-height: 48px;
    position: relative;
    margin-top: 34px;
    width: 179px;
    margin-left: calc(50.0% + -89px);
  }
  .PerfilScreen > .layoutFlow > .state0_elSendMessageButton > * { 
  width: 179px;
  height: 48px;
  }
  
}
@media (min-width: 1024px) {
  .PerfilScreen > .layoutFlow > .state0_elSendMessageButton {
    line-height: 48px;
    position: relative;
    margin-top: 34px;
    width: 179px;
    margin-left: calc(50.0% + -89px);
  }
  .PerfilScreen > .layoutFlow > .state0_elSendMessageButton > * { 
  width: 179px;
  height: 48px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PerfilScreen > .layoutFlow > .state0_elSpacer2 {
    position: relative;
    margin-top: 43px;
    width: 10px;
    margin-left: 140px;
  }
  .PerfilScreen > .layoutFlow > .state0_elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .PerfilScreen > .layoutFlow > .state0_elSpacer2 {
    position: relative;
    margin-top: 43px;
    width: 10px;
    margin-left: 140px;
  }
  .PerfilScreen > .layoutFlow > .state0_elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .PerfilScreen > .layoutFlow > .state0_elSpacer2 {
    position: relative;
    margin-top: 42px;
    width: 10px;
    margin-left: 139px;
  }
  .PerfilScreen > .layoutFlow > .state0_elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .PerfilScreen > .layoutFlow > .state0_elSpacer2 {
    position: relative;
    margin-top: 42px;
    width: 10px;
    margin-left: 139px;
  }
  .PerfilScreen > .layoutFlow > .state0_elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PerfilScreen > .screenFgContainer > .foreground > .state0_elSupabaseJS {
    position: absolute;
    left: 0px;
    top: 559px;
    width: 23px;
    height: 23px;
  }
@media (min-width: 568px) {
  .PerfilScreen > .screenFgContainer > .foreground > .state0_elSupabaseJS {
    left: 0px;
    top: 559px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 768px) {
  .PerfilScreen > .screenFgContainer > .foreground > .state0_elSupabaseJS {
    left: 0px;
    top: 556px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 1024px) {
  .PerfilScreen > .screenFgContainer > .foreground > .state0_elSupabaseJS {
    left: 0px;
    top: 556px;
    width: 23px;
    height: 23px;
  }
}


/* Narrowest size for this element's responsive layout */
  .PerfilScreen > .screenFgContainer > .foreground > .state0_elImage {
    position: fixed;
    right: 11px;
    top: 8px;
    width: 36px;
    height: 31px;
  }
@media (min-width: 568px) {
  .PerfilScreen > .screenFgContainer > .foreground > .state0_elImage {
    right: 11px;
    top: 8px;
    width: 36px;
    height: 31px;
  }
}
@media (min-width: 768px) {
  .PerfilScreen > .screenFgContainer > .foreground > .state0_elImage {
    right: 11px;
    top: 8px;
    width: 36px;
    height: 31px;
  }
}
@media (min-width: 1024px) {
  .PerfilScreen > .screenFgContainer > .foreground > .state0_elImage {
    right: 11px;
    top: 8px;
    width: 36px;
    height: 31px;
  }
}

.PerfilScreen .state1_elFab222580 button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.PerfilScreen .state1_elFab222580 button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .PerfilScreen > .layoutFlow > .flowRow > .state1_elFab222580 {
    line-height: 46px;
    position: absolute;
    margin-top: 12px;
    width: 100%;
  }
  .PerfilScreen > .layoutFlow > .flowRow > .state1_elFab222580 > * { 
  margin-left: auto;
  width: 47px;
  margin-right: 11px;
  height: 46px;
  }
  
  .flowRow_PerfilScreen_state1_elFab222580_222580 { 
  }
  
@media (min-width: 568px) {
  .PerfilScreen > .layoutFlow > .flowRow > .state1_elFab222580 {
    line-height: 46px;
    position: absolute;
    margin-top: 12px;
    width: 100%;
  }
  .PerfilScreen > .layoutFlow > .flowRow > .state1_elFab222580 > * { 
  margin-left: auto;
  width: 47px;
  margin-right: 11px;
  height: 46px;
  }
  
  .flowRow_PerfilScreen_state1_elFab222580_222580 { 
  }
  
}
@media (min-width: 768px) {
  .PerfilScreen > .layoutFlow > .flowRow > .state1_elFab222580 {
    line-height: 46px;
    position: absolute;
    margin-top: 12px;
    width: 100%;
  }
  .PerfilScreen > .layoutFlow > .flowRow > .state1_elFab222580 > * { 
  margin-left: auto;
  width: 47px;
  margin-right: 11px;
  height: 46px;
  }
  
  .flowRow_PerfilScreen_state1_elFab222580_222580 { 
  }
  
}
@media (min-width: 1024px) {
  .PerfilScreen > .layoutFlow > .flowRow > .state1_elFab222580 {
    line-height: 46px;
    position: absolute;
    margin-top: 12px;
    width: 100%;
  }
  .PerfilScreen > .layoutFlow > .flowRow > .state1_elFab222580 > * { 
  margin-left: auto;
  width: 47px;
  margin-right: 11px;
  height: 46px;
  }
  
  .flowRow_PerfilScreen_state1_elFab222580_222580 { 
  }
  
}


/* Narrowest size for this element's responsive layout */
  .FavorScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .FavorScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .FavorScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .FavorScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .FavorScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .FavorScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .FavorScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .FavorScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .FavorScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .FavorScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .FavorScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .FavorScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}

.FavorScreen .elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.FavorScreen .elButton button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .FavorScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 308px;
    width: 226px;
    margin-left: calc(50.0% + -113px);
  }
  .FavorScreen > .layoutFlow > .elButton > * { 
  width: 226px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .FavorScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 308px;
    width: 226px;
    margin-left: calc(50.0% + -113px);
  }
  .FavorScreen > .layoutFlow > .elButton > * { 
  width: 226px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .FavorScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 306px;
    width: 225px;
    margin-left: calc(50.0% + -112px);
  }
  .FavorScreen > .layoutFlow > .elButton > * { 
  width: 225px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .FavorScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 306px;
    width: 225px;
    margin-left: calc(50.0% + -112px);
  }
  .FavorScreen > .layoutFlow > .elButton > * { 
  width: 225px;
  height: 35px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .FavorScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    position: absolute;
    left: 20px;
    top: 588px;
    width: 23px;
    height: 23px;
  }
@media (min-width: 568px) {
  .FavorScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    left: 20px;
    top: 588px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 768px) {
  .FavorScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    left: 20px;
    top: 584px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 1024px) {
  .FavorScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    left: 20px;
    top: 584px;
    width: 23px;
    height: 23px;
  }
}

.FavorScreen .elComp {
}

.FavorScreen .elComp.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .FavorScreen > .screenFgContainer > .foreground > .elComp {
    position: absolute;
    left: calc(8.9% + -1px);
    top: 85px;
    width: 292px;
    height: 238px;
  }
  .FavorScreen > .screenFgContainer > .foreground > .elComp > .background > .containerMinHeight {
    min-height: 238px;
  }

@media (min-width: 568px) {
  .FavorScreen > .screenFgContainer > .foreground > .elComp {
    left: calc(8.9% + -1px);
    top: 85px;
    width: 292px;
    height: 238px;
  }
  .FavorScreen > .screenFgContainer > .foreground > .elComp > .background > .containerMinHeight {
    min-height: 238px;
  }

}
@media (min-width: 768px) {
  .FavorScreen > .screenFgContainer > .foreground > .elComp {
    left: calc(8.9% + -1px);
    top: 84px;
    width: 291px;
    height: 236px;
  }
  .FavorScreen > .screenFgContainer > .foreground > .elComp > .background > .containerMinHeight {
    min-height: 238px;
  }

}
@media (min-width: 1024px) {
  .FavorScreen > .screenFgContainer > .foreground > .elComp {
    left: calc(8.9% + -1px);
    top: 84px;
    width: 291px;
    height: 236px;
  }
  .FavorScreen > .screenFgContainer > .foreground > .elComp > .background > .containerMinHeight {
    min-height: 238px;
  }

}

/* This component has a fixed-size layout */
  .FavorComponent {
    min-height: 242px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 362px */
    height: 100%;
  }
  .FavorComponent > .background > .containerMinHeight {
    min-height: 242px;
  }


  .FavorComponent > .foreground > .elTextBlock8 {
    line-height: 19px;
    position: absolute;
    left: 56px;
    top: 137px;
    width: 50px;
    height: 22px;
  }


  .FavorComponent > .foreground > .elTextBlock7 {
    line-height: 24px;
    position: absolute;
    left: 5px;
    top: 131px;
    width: 77px;
    height: 27px;
  }


  .FavorComponent > .foreground > .elTextBlock6 {
    line-height: 19px;
    position: absolute;
    left: 6px;
    top: 109px;
    width: 162px;
    height: 22px;
  }


  .FavorComponent > .foreground > .elTextBlock5 {
    line-height: 24px;
    position: absolute;
    left: 6px;
    top: 79px;
    width: 156px;
    height: 27px;
  }


  .FavorComponent > .foreground > .elTextBlock4 {
    line-height: 19px;
    position: absolute;
    left: 5px;
    top: 58px;
    width: 228px;
    height: 22px;
  }


  .FavorComponent > .foreground > .elTextBlock3 {
    line-height: 24px;
    position: absolute;
    left: 6px;
    top: 23px;
    width: 128px;
    height: 27px;
  }


  .FavorComponent > .foreground > .elTextBlock2 {
    line-height: 19px;
    position: absolute;
    left: 54px;
    top: 0px;
    width: 253px;
    height: 22px;
  }


  .FavorComponent > .foreground > .elTextBlock {
    line-height: 19px;
    position: absolute;
    left: 6px;
    top: 0px;
    width: 51px;
    height: 22px;
  }


  .FavorComponent > .foreground > .elMomentJS {
    position: absolute;
    left: 110px;
    top: 319px;
    width: 23px;
    height: 23px;
  }


/* Narrowest size for this element's responsive layout */
  .AQuienesDeboScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .AQuienesDeboScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .AQuienesDeboScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .AQuienesDeboScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .AQuienesDeboScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .AQuienesDeboScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .AQuienesDeboScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .AQuienesDeboScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .AQuienesDeboScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .AQuienesDeboScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .AQuienesDeboScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .AQuienesDeboScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}

.AQuienesDeboScreen .elList li {
  margin-bottom: 5px;
}



/* Narrowest size for this element's responsive layout */
  .AQuienesDeboScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .AQuienesDeboScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .AQuienesDeboScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .AQuienesDeboScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .AQuienesDeboScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .AQuienesDeboScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .AQuienesDeboScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .AQuienesDeboScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AQuienesDeboScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    position: absolute;
    left: 70px;
    top: 599px;
    width: 23px;
    height: 23px;
  }
@media (min-width: 568px) {
  .AQuienesDeboScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    left: 70px;
    top: 599px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 768px) {
  .AQuienesDeboScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    left: 70px;
    top: 595px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 1024px) {
  .AQuienesDeboScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    left: 70px;
    top: 595px;
    width: 23px;
    height: 23px;
  }
}


/* Narrowest size for this element's responsive layout */
  .QuienesMeDebenScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .QuienesMeDebenScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .QuienesMeDebenScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .QuienesMeDebenScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .QuienesMeDebenScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .QuienesMeDebenScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .QuienesMeDebenScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .QuienesMeDebenScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .QuienesMeDebenScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .QuienesMeDebenScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .QuienesMeDebenScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .QuienesMeDebenScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}

.QuienesMeDebenScreen .elList li {
  margin-bottom: 5px;
}



/* Narrowest size for this element's responsive layout */
  .QuienesMeDebenScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .QuienesMeDebenScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .QuienesMeDebenScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .QuienesMeDebenScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .QuienesMeDebenScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .QuienesMeDebenScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .QuienesMeDebenScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .QuienesMeDebenScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .QuienesMeDebenScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    position: absolute;
    left: 0px;
    top: 582px;
    width: 23px;
    height: 23px;
  }
@media (min-width: 568px) {
  .QuienesMeDebenScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    left: 0px;
    top: 582px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 768px) {
  .QuienesMeDebenScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    left: 0px;
    top: 578px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 1024px) {
  .QuienesMeDebenScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    left: 0px;
    top: 578px;
    width: 23px;
    height: 23px;
  }
}

/* This component has a fixed-size layout */
  .BalancesPositiveItem {
    min-height: 79px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 333px */
    height: 100%;
  }
  .BalancesPositiveItem > .background > .containerMinHeight {
    min-height: 79px;
  }


  .BalancesPositiveItem > .background > .elCard {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 78px;
  }

.BalancesPositiveItem .elHotspot button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



  .BalancesPositiveItem > .layoutFlow > .flowRow > .elHotspot {
    position: absolute;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .BalancesPositiveItem > .layoutFlow > .flowRow > .elHotspot > * { 
    width: 100%;
  height: 82px;
  }
  
  .flowRow_BalancesPositiveItem_elHotspot_578614 { 
  }
  


  .BalancesPositiveItem > .layoutFlow > .elHours {
    line-height: 24px;
    position: relative;
    margin-top: 9px;
    width: 100%;
  }
  .BalancesPositiveItem > .layoutFlow > .elHours > * { 
  margin-left: auto;
  width: 123px;
  margin-right: 51.4%;
  }
  


  .BalancesPositiveItem > .layoutFlow > .flowRow > .elTextBlock3 {
    line-height: 19px;
    position: absolute;
    margin-top: -18px;
    width: 156px;
    margin-left: calc(50.0% + 4px);
  }
  .BalancesPositiveItem > .layoutFlow > .flowRow > .elTextBlock3 > * { 
  width: 156px;
  }
  
  .flowRow_BalancesPositiveItem_elTextBlock3_372869 { 
  }
  


  .BalancesPositiveItem > .layoutFlow > .flowRow > .elName {
    line-height: 24px;
    position: absolute;
    top: 10px;
    margin-left: 50.0%;
    margin-right: 91px;
    width: calc(50.0% - 91px);
  }
  .BalancesPositiveItem > .layoutFlow > .flowRow > .elName > * { 
    width: 100%;
  }
  


/* Narrowest size for this element's responsive layout */
  .FavoresPorUsuarioScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .FavoresPorUsuarioScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .FavoresPorUsuarioScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .FavoresPorUsuarioScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .FavoresPorUsuarioScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .FavoresPorUsuarioScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .FavoresPorUsuarioScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .FavoresPorUsuarioScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .FavoresPorUsuarioScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .FavoresPorUsuarioScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .FavoresPorUsuarioScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .FavoresPorUsuarioScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .FavoresPorUsuarioScreen > .layoutFlow > .elTextBlock {
    line-height: 24px;
    position: relative;
    margin-top: 12px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .FavoresPorUsuarioScreen > .layoutFlow > .elTextBlock > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .FavoresPorUsuarioScreen > .layoutFlow > .elTextBlock {
    line-height: 24px;
    position: relative;
    margin-top: 12px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .FavoresPorUsuarioScreen > .layoutFlow > .elTextBlock > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .FavoresPorUsuarioScreen > .layoutFlow > .elTextBlock {
    line-height: 24px;
    position: relative;
    margin-top: 12px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .FavoresPorUsuarioScreen > .layoutFlow > .elTextBlock > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .FavoresPorUsuarioScreen > .layoutFlow > .elTextBlock {
    line-height: 24px;
    position: relative;
    margin-top: 12px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .FavoresPorUsuarioScreen > .layoutFlow > .elTextBlock > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .FavoresPorUsuarioScreen > .layoutFlow > .elRect {
    position: relative;
    margin-top: 7px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .FavoresPorUsuarioScreen > .layoutFlow > .elRect > * { 
    width: 100%;
  height: 12px;
  }
  
@media (min-width: 568px) {
  .FavoresPorUsuarioScreen > .layoutFlow > .elRect {
    position: relative;
    margin-top: 7px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .FavoresPorUsuarioScreen > .layoutFlow > .elRect > * { 
    width: 100%;
  height: 12px;
  }
  
}
@media (min-width: 768px) {
  .FavoresPorUsuarioScreen > .layoutFlow > .elRect {
    position: relative;
    margin-top: 7px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .FavoresPorUsuarioScreen > .layoutFlow > .elRect > * { 
    width: 100%;
  height: 12px;
  }
  
}
@media (min-width: 1024px) {
  .FavoresPorUsuarioScreen > .layoutFlow > .elRect {
    position: relative;
    margin-top: 7px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .FavoresPorUsuarioScreen > .layoutFlow > .elRect > * { 
    width: 100%;
  height: 12px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .FavoresPorUsuarioScreen > .layoutFlow > .flowRow > .elRect2 {
    position: absolute;
    margin-top: -11px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .FavoresPorUsuarioScreen > .layoutFlow > .flowRow > .elRect2 > * { 
    width: 100%;
  height: 35px;
  }
  
  .flowRow_FavoresPorUsuarioScreen_elRect2_19534 { 
  }
  
@media (min-width: 568px) {
  .FavoresPorUsuarioScreen > .layoutFlow > .flowRow > .elRect2 {
    position: absolute;
    margin-top: -11px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .FavoresPorUsuarioScreen > .layoutFlow > .flowRow > .elRect2 > * { 
    width: 100%;
  height: 35px;
  }
  
  .flowRow_FavoresPorUsuarioScreen_elRect2_19534 { 
  }
  
}
@media (min-width: 768px) {
  .FavoresPorUsuarioScreen > .layoutFlow > .flowRow > .elRect2 {
    position: absolute;
    margin-top: -11px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .FavoresPorUsuarioScreen > .layoutFlow > .flowRow > .elRect2 > * { 
    width: 100%;
  height: 35px;
  }
  
  .flowRow_FavoresPorUsuarioScreen_elRect2_19534 { 
  }
  
}
@media (min-width: 1024px) {
  .FavoresPorUsuarioScreen > .layoutFlow > .flowRow > .elRect2 {
    position: absolute;
    margin-top: -11px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .FavoresPorUsuarioScreen > .layoutFlow > .flowRow > .elRect2 > * { 
    width: 100%;
  height: 35px;
  }
  
  .flowRow_FavoresPorUsuarioScreen_elRect2_19534 { 
  }
  
}


/* Narrowest size for this element's responsive layout */
  .FavoresPorUsuarioScreen > .layoutFlow > .elText {
    line-height: 24px;
    position: relative;
    margin-top: -4px;
    margin-left: 9px;
    margin-right: 0px;
    width: calc(100.0% - 9px);
  }
  .FavoresPorUsuarioScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .FavoresPorUsuarioScreen > .layoutFlow > .elText {
    line-height: 24px;
    position: relative;
    margin-top: -4px;
    margin-left: 9px;
    margin-right: 0px;
    width: calc(100.0% - 9px);
  }
  .FavoresPorUsuarioScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .FavoresPorUsuarioScreen > .layoutFlow > .elText {
    line-height: 24px;
    position: relative;
    margin-top: -4px;
    margin-left: 9px;
    margin-right: 0px;
    width: calc(100.0% - 9px);
  }
  .FavoresPorUsuarioScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .FavoresPorUsuarioScreen > .layoutFlow > .elText {
    line-height: 24px;
    position: relative;
    margin-top: -4px;
    margin-left: 9px;
    margin-right: 0px;
    width: calc(100.0% - 9px);
  }
  .FavoresPorUsuarioScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}

.FavoresPorUsuarioScreen .elList li {
  margin-bottom: 5px;
}



/* Narrowest size for this element's responsive layout */
  .FavoresPorUsuarioScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 6px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .FavoresPorUsuarioScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .FavoresPorUsuarioScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 6px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .FavoresPorUsuarioScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .FavoresPorUsuarioScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 6px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .FavoresPorUsuarioScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .FavoresPorUsuarioScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 6px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .FavoresPorUsuarioScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .FavoresPorUsuarioScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    position: absolute;
    left: 285px;
    top: 558px;
    width: 23px;
    height: 23px;
  }
@media (min-width: 568px) {
  .FavoresPorUsuarioScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    left: 285px;
    top: 558px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 768px) {
  .FavoresPorUsuarioScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    left: 283px;
    top: 554px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 1024px) {
  .FavoresPorUsuarioScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    left: 283px;
    top: 554px;
    width: 23px;
    height: 23px;
  }
}

/* This component has a fixed-size layout */
  .FavorListItem {
    width: 100%; /* This component was designed using a width of 322px */
    height: 100%;
  }


  .FavorListItem > .layoutFlow > .elText5 {
    line-height: 24px;
    position: relative;
    margin-top: 16px;
    width: 238px;
    margin-left: 52px;
  }
  .FavorListItem > .layoutFlow > .elText5 > * { 
  width: 238px;
  }
  


  .FavorListItem > .layoutFlow > .elText {
    line-height: 19px;
    position: relative;
    margin-top: 14px;
    width: 60px;
    margin-left: 30px;
  }
  .FavorListItem > .layoutFlow > .elText > * { 
  width: 60px;
  }
  


  .FavorListItem > .layoutFlow > .elText2 {
    line-height: 19px;
    position: relative;
    margin-top: -20px;
    width: 238px;
    margin-left: 130px;
  }
  .FavorListItem > .layoutFlow > .elText2 > * { 
  width: 238px;
  }
  


  .FavorListItem > .layoutFlow > .elText3 {
    line-height: 19px;
    position: relative;
    margin-top: 10px;
    width: 114px;
    margin-left: 30px;
  }
  .FavorListItem > .layoutFlow > .elText3 > * { 
  width: 114px;
  }
  


  .FavorListItem > .layoutFlow > .elText4 {
    line-height: 19px;
    position: relative;
    margin-top: -18px;
    width: 238px;
    margin-left: 130px;
  }
  .FavorListItem > .layoutFlow > .elText4 > * { 
  width: 238px;
  }
  


  .FavorListItem > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 8px;
    width: 10px;
    margin-left: 140px;
  }
  .FavorListItem > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  

.FavorListItem .elHotspot {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



  .FavorListItem > .foreground > .elHotspot {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 322px;
    height: 120px;
  }

/* This component has a fixed-size layout */
  .MisHabilidadesComp {
    width: 100%; /* This component was designed using a width of 323px */
    height: 100%;
  }


  .MisHabilidadesComp > .layoutFlow > .elText {
    line-height: 24px;
    position: relative;
    margin-top: 18px;
    width: 140px;
    margin-left: 18px;
  }
  .MisHabilidadesComp > .layoutFlow > .elText > * { 
  width: 140px;
  }
  


  .MisHabilidadesComp > .layoutFlow > .flowRow > .elTextBlock {
    line-height: 52px;
    position: absolute;
    margin-top: -22px;
    width: 100%;
  }
  .MisHabilidadesComp > .layoutFlow > .flowRow > .elTextBlock > * { 
  margin-left: auto;
  width: 88px;
  margin-right: 9px;
  }
  
  .flowRow_MisHabilidadesComp_elTextBlock_31216 { 
  }
  


  .MisHabilidadesComp > .layoutFlow > .elText2 {
    line-height: 19px;
    position: relative;
    margin-top: 4px;
    width: 238px;
    margin-left: 18px;
  }
  .MisHabilidadesComp > .layoutFlow > .elText2 > * { 
  width: 238px;
  }
  


  .MisHabilidadesComp > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 6px;
    width: 10px;
    margin-left: 250px;
  }
  .MisHabilidadesComp > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  

/* This component has a fixed-size layout */
  .HabilidadesALosQueTengoAcceso {
    width: 100%; /* This component was designed using a width of 325px */
    height: 100%;
  }


  .HabilidadesALosQueTengoAcceso > .layoutFlow > .elText {
    line-height: 24px;
    position: relative;
    margin-top: 11px;
    width: 252px;
    margin-left: 18px;
  }
  .HabilidadesALosQueTengoAcceso > .layoutFlow > .elText > * { 
  width: 252px;
  }
  


  .HabilidadesALosQueTengoAcceso > .layoutFlow > .flowRow > .elText3 {
    line-height: 52px;
    position: absolute;
    margin-top: -26px;
    width: 100%;
  }
  .HabilidadesALosQueTengoAcceso > .layoutFlow > .flowRow > .elText3 > * { 
  margin-left: auto;
  width: 69px;
  margin-right: 9px;
  }
  
  .flowRow_HabilidadesALosQueTengoAcceso_elText3_676513 { 
  }
  


  .HabilidadesALosQueTengoAcceso > .layoutFlow > .elText2 {
    line-height: 19px;
    position: relative;
    margin-top: -1px;
    width: 238px;
    margin-left: 18px;
  }
  .HabilidadesALosQueTengoAcceso > .layoutFlow > .elText2 > * { 
  width: 238px;
  }
  


  .HabilidadesALosQueTengoAcceso > .layoutFlow > .elText4 {
    line-height: 19px;
    position: relative;
    margin-top: 4px;
    width: 238px;
    margin-left: 18px;
  }
  .HabilidadesALosQueTengoAcceso > .layoutFlow > .elText4 > * { 
  width: 238px;
  }
  


  .HabilidadesALosQueTengoAcceso > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 12px;
    width: 10px;
    margin-left: 190px;
  }
  .HabilidadesALosQueTengoAcceso > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  


/* Narrowest size for this element's responsive layout */
  .MisHabilidadesScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .MisHabilidadesScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .MisHabilidadesScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .MisHabilidadesScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .MisHabilidadesScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .MisHabilidadesScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .MisHabilidadesScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .MisHabilidadesScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .MisHabilidadesScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .MisHabilidadesScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .MisHabilidadesScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .MisHabilidadesScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MisHabilidadesScreen > .layoutFlow > .elText {
    line-height: 19px;
    position: relative;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 16px;
    width: calc(100.0% - 26px);
  }
  .MisHabilidadesScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MisHabilidadesScreen > .layoutFlow > .elText {
    line-height: 19px;
    position: relative;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 16px;
    width: calc(100.0% - 26px);
  }
  .MisHabilidadesScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MisHabilidadesScreen > .layoutFlow > .elText {
    line-height: 19px;
    position: relative;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 16px;
    width: calc(100.0% - 26px);
  }
  .MisHabilidadesScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MisHabilidadesScreen > .layoutFlow > .elText {
    line-height: 19px;
    position: relative;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 16px;
    width: calc(100.0% - 26px);
  }
  .MisHabilidadesScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}

.MisHabilidadesScreen .elList li {
  margin-bottom: 5px;
}



/* Narrowest size for this element's responsive layout */
  .MisHabilidadesScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 7px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MisHabilidadesScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MisHabilidadesScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 7px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MisHabilidadesScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MisHabilidadesScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 7px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MisHabilidadesScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MisHabilidadesScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 7px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MisHabilidadesScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}

.MisHabilidadesScreen .elFormulario {
}

.MisHabilidadesScreen .elFormulario.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .MisHabilidadesScreen > .screenFgContainer > .foreground > .elFormulario {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 49px;
    width: 100.0%;
    height: 207px;
  }
  .MisHabilidadesScreen > .screenFgContainer > .foreground > .elFormulario > .background > .containerMinHeight {
    min-height: 207px;
  }

@media (min-width: 568px) {
  .MisHabilidadesScreen > .screenFgContainer > .foreground > .elFormulario {
    left: 0px;
    right: 0px;
    top: 49px;
    width: 100.0%;
    height: 207px;
  }
  .MisHabilidadesScreen > .screenFgContainer > .foreground > .elFormulario > .background > .containerMinHeight {
    min-height: 207px;
  }

}
@media (min-width: 768px) {
  .MisHabilidadesScreen > .screenFgContainer > .foreground > .elFormulario {
    left: 0px;
    right: 0px;
    top: 49px;
    width: 100.0%;
    height: 206px;
  }
  .MisHabilidadesScreen > .screenFgContainer > .foreground > .elFormulario > .background > .containerMinHeight {
    min-height: 207px;
  }

}
@media (min-width: 1024px) {
  .MisHabilidadesScreen > .screenFgContainer > .foreground > .elFormulario {
    left: 0px;
    right: 0px;
    top: 49px;
    width: 100.0%;
    height: 206px;
  }
  .MisHabilidadesScreen > .screenFgContainer > .foreground > .elFormulario > .background > .containerMinHeight {
    min-height: 207px;
  }

}

.MisHabilidadesScreen .elFab {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.MisHabilidadesScreen .elFab:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .MisHabilidadesScreen > .screenFgContainer > .foreground > .elFab {
    line-height: 68px;
    position: fixed;
    left: 10px;
    bottom: 9px;
    width: 68px;
    height: 68px;
  }
@media (min-width: 568px) {
  .MisHabilidadesScreen > .screenFgContainer > .foreground > .elFab {
    line-height: 68px;
    left: 10px;
    bottom: 9px;
    width: 68px;
    height: 68px;
  }
}
@media (min-width: 768px) {
  .MisHabilidadesScreen > .screenFgContainer > .foreground > .elFab {
    line-height: 68px;
    left: 10px;
    bottom: 9px;
    width: 68px;
    height: 68px;
  }
}
@media (min-width: 1024px) {
  .MisHabilidadesScreen > .screenFgContainer > .foreground > .elFab {
    line-height: 68px;
    left: 10px;
    bottom: 9px;
    width: 68px;
    height: 68px;
  }
}


/* Narrowest size for this element's responsive layout */
  .HabilidadesDisponiblesScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .HabilidadesDisponiblesScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .HabilidadesDisponiblesScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .HabilidadesDisponiblesScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .HabilidadesDisponiblesScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .HabilidadesDisponiblesScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .HabilidadesDisponiblesScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .HabilidadesDisponiblesScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .HabilidadesDisponiblesScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .HabilidadesDisponiblesScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .HabilidadesDisponiblesScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .HabilidadesDisponiblesScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}

.HabilidadesDisponiblesScreen .elList li {
  margin-bottom: 5px;
}



/* Narrowest size for this element's responsive layout */
  .HabilidadesDisponiblesScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .HabilidadesDisponiblesScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .HabilidadesDisponiblesScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .HabilidadesDisponiblesScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .HabilidadesDisponiblesScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .HabilidadesDisponiblesScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .HabilidadesDisponiblesScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .HabilidadesDisponiblesScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}

/* This component has a fixed-size layout */
  .FormularioDeHabilidades {
    width: 100%; /* This component was designed using a width of 356px */
    height: 100%;
  }


  .FormularioDeHabilidades > .background > .elCard {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 227px;
  }


  .FormularioDeHabilidades > .layoutFlow > .elHabilidad {
    position: relative;
    margin-top: 16px;
    margin-left: 10px;
    margin-right: 17px;
    width: calc(100.0% - 27px);
  }
  .FormularioDeHabilidades > .layoutFlow > .elHabilidad > * { 
    width: 100%;
  }
  


  .FormularioDeHabilidades > .layoutFlow > .elEstudios {
    position: relative;
    margin-top: 7px;
    width: 180px;
    margin-left: 105px;
  }
  .FormularioDeHabilidades > .layoutFlow > .elEstudios > * { 
  width: 180px;
  }
  


  .FormularioDeHabilidades > .layoutFlow > .elTiempo_de_estudios {
    position: relative;
    margin-top: -30px;
    width: 84px;
    margin-left: 10px;
  }
  .FormularioDeHabilidades > .layoutFlow > .elTiempo_de_estudios > * { 
  width: 84px;
  }
  


  .FormularioDeHabilidades > .layoutFlow > .elExperiencia {
    position: relative;
    margin-top: 2px;
    width: 180px;
    margin-left: 106px;
  }
  .FormularioDeHabilidades > .layoutFlow > .elExperiencia > * { 
  width: 180px;
  }
  


  .FormularioDeHabilidades > .layoutFlow > .elTiempo_de_experiencia {
    position: relative;
    margin-top: -34px;
    width: 84px;
    margin-left: 10px;
  }
  .FormularioDeHabilidades > .layoutFlow > .elTiempo_de_experiencia > * { 
  width: 84px;
  }
  

.FormularioDeHabilidades .elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.FormularioDeHabilidades .elButton button:focus {
  outline: none;
}



  .FormularioDeHabilidades > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 16px;
    width: 119px;
    margin-left: 10px;
  }
  .FormularioDeHabilidades > .layoutFlow > .elButton > * { 
  width: 119px;
  height: 35px;
  }
  


  .FormularioDeHabilidades > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 28px;
    width: 10px;
    margin-left: 240px;
  }
  .FormularioDeHabilidades > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  


  .FormularioDeHabilidades > .foreground > .elSupabaseJS {
    position: absolute;
    left: 311px;
    top: 236px;
    width: 23px;
    height: 23px;
  }

/* This component has a fixed-size layout */
  .Habilidad {
    width: 100%; /* This component was designed using a width of 322px */
    height: 100%;
  }


  .Habilidad > .layoutFlow > .elText {
    line-height: 24px;
    position: relative;
    margin-top: 12px;
    width: 238px;
    margin-left: 10px;
  }
  .Habilidad > .layoutFlow > .elText > * { 
  width: 238px;
  }
  


  .Habilidad > .layoutFlow > .elText2 {
    line-height: 19px;
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 10px;
  }
  .Habilidad > .layoutFlow > .elText2 > * { 
  width: 238px;
  }
  


  .Habilidad > .layoutFlow > .elTextCopy {
    line-height: 19px;
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 10px;
  }
  .Habilidad > .layoutFlow > .elTextCopy > * { 
  width: 238px;
  }
  


  .Habilidad > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 12px;
    width: 10px;
    margin-left: 170px;
  }
  .Habilidad > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  

.Habilidad .elIconButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Habilidad .elIconButton:focus {
  outline: none;
}



  .Habilidad > .foreground > .elIconButton {
    position: absolute;
    right: 10px;
    bottom: 9px;
    width: 30px;
    height: 31px;
  }


  .Habilidad > .foreground > .elSupabaseJS {
    position: absolute;
    left: 285px;
    top: 140px;
    width: 23px;
    height: 23px;
  }

/* This component has a fixed-size layout */
  .BalancesNegativeItem {
    width: 100%; /* This component was designed using a width of 322px */
    height: 100%;
  }

.BalancesNegativeItem .elNamesAndHours {
}

.BalancesNegativeItem .elNamesAndHours.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .BalancesNegativeItem > .layoutFlow > .elNamesAndHours {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .BalancesNegativeItem > .layoutFlow > .elNamesAndHours > * { 
    width: 100%;
  }
  

/* This component has a fixed-size layout */
  .ListItem1 {
    width: 100%; /* This component was designed using a width of 322px */
    height: 100%;
  }


  .ListItem1 > .layoutFlow > .elText {
    line-height: 24px;
    position: relative;
    margin-top: 22px;
    width: 238px;
    margin-left: 10px;
  }
  .ListItem1 > .layoutFlow > .elText > * { 
  width: 238px;
  }
  


  .ListItem1 > .layoutFlow > .elText3 {
    line-height: 19px;
    position: relative;
    margin-top: -21px;
    width: 100%;
  }
  .ListItem1 > .layoutFlow > .elText3 > * { 
  margin-left: auto;
  width: 162px;
  margin-right: 60px;
  }
  


  .ListItem1 > .layoutFlow > .elText2 {
    line-height: 19px;
    position: relative;
    margin-top: -18px;
    width: 100%;
  }
  .ListItem1 > .layoutFlow > .elText2 > * { 
  margin-left: auto;
  width: 45px;
  margin-right: 10px;
  }
  


  .ListItem1 > .layoutFlow > .elText4 {
    line-height: 19px;
    position: relative;
    margin-top: 14px;
    margin-left: 10px;
    margin-right: 13px;
    width: calc(100.0% - 24px);
  }
  .ListItem1 > .layoutFlow > .elText4 > * { 
    width: 100%;
  }
  

.ListItem1 .elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.ListItem1 .elButton button:focus {
  outline: none;
}



  .ListItem1 > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 12px;
    width: 145px;
    margin-left: 90px;
  }
  .ListItem1 > .layoutFlow > .elButton > * { 
  width: 145px;
  height: 35px;
  }
  


  .ListItem1 > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 330px;
  }
  .ListItem1 > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  


/* Narrowest size for this element's responsive layout */
  .SolicitarUnFavorScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .SolicitarUnFavorScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .SolicitarUnFavorScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .SolicitarUnFavorScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .SolicitarUnFavorScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .SolicitarUnFavorScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .SolicitarUnFavorScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .SolicitarUnFavorScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .SolicitarUnFavorScreen > .layoutFlow > .elText {
    line-height: 19px;
    position: relative;
    margin-top: 14px;
    width: 238px;
    margin-left: 20px;
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elText > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .SolicitarUnFavorScreen > .layoutFlow > .elText {
    line-height: 19px;
    position: relative;
    margin-top: 14px;
    width: 238px;
    margin-left: 20px;
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elText > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .SolicitarUnFavorScreen > .layoutFlow > .elText {
    line-height: 19px;
    position: relative;
    margin-top: 14px;
    width: 237px;
    margin-left: 20px;
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elText > * { 
  width: 237px;
  }
  
}
@media (min-width: 1024px) {
  .SolicitarUnFavorScreen > .layoutFlow > .elText {
    line-height: 19px;
    position: relative;
    margin-top: 14px;
    width: 237px;
    margin-left: 20px;
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elText > * { 
  width: 237px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .SolicitarUnFavorScreen > .layoutFlow > .elText2 {
    line-height: 24px;
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 20px;
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elText2 > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .SolicitarUnFavorScreen > .layoutFlow > .elText2 {
    line-height: 24px;
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 20px;
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elText2 > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .SolicitarUnFavorScreen > .layoutFlow > .elText2 {
    line-height: 24px;
    position: relative;
    margin-top: 0px;
    width: 237px;
    margin-left: 20px;
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elText2 > * { 
  width: 237px;
  }
  
}
@media (min-width: 1024px) {
  .SolicitarUnFavorScreen > .layoutFlow > .elText2 {
    line-height: 24px;
    position: relative;
    margin-top: 0px;
    width: 237px;
    margin-left: 20px;
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elText2 > * { 
  width: 237px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .SolicitarUnFavorScreen > .layoutFlow > .elText3 {
    line-height: 19px;
    position: relative;
    margin-top: 15px;
    width: 238px;
    margin-left: 20px;
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elText3 > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .SolicitarUnFavorScreen > .layoutFlow > .elText3 {
    line-height: 19px;
    position: relative;
    margin-top: 15px;
    width: 238px;
    margin-left: 20px;
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elText3 > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .SolicitarUnFavorScreen > .layoutFlow > .elText3 {
    line-height: 19px;
    position: relative;
    margin-top: 15px;
    width: 237px;
    margin-left: 20px;
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elText3 > * { 
  width: 237px;
  }
  
}
@media (min-width: 1024px) {
  .SolicitarUnFavorScreen > .layoutFlow > .elText3 {
    line-height: 19px;
    position: relative;
    margin-top: 15px;
    width: 237px;
    margin-left: 20px;
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elText3 > * { 
  width: 237px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .SolicitarUnFavorScreen > .layoutFlow > .elPicker {
    position: relative;
    margin-top: -3px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elPicker > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .SolicitarUnFavorScreen > .layoutFlow > .elPicker {
    position: relative;
    margin-top: -3px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elPicker > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .SolicitarUnFavorScreen > .layoutFlow > .elPicker {
    position: relative;
    margin-top: -3px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elPicker > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .SolicitarUnFavorScreen > .layoutFlow > .elPicker {
    position: relative;
    margin-top: -3px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elPicker > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .SolicitarUnFavorScreen > .layoutFlow > .elText4 {
    line-height: 19px;
    position: relative;
    margin-top: 28px;
    width: 238px;
    margin-left: 20px;
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elText4 > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .SolicitarUnFavorScreen > .layoutFlow > .elText4 {
    line-height: 19px;
    position: relative;
    margin-top: 28px;
    width: 238px;
    margin-left: 20px;
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elText4 > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .SolicitarUnFavorScreen > .layoutFlow > .elText4 {
    line-height: 19px;
    position: relative;
    margin-top: 28px;
    width: 237px;
    margin-left: 20px;
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elText4 > * { 
  width: 237px;
  }
  
}
@media (min-width: 1024px) {
  .SolicitarUnFavorScreen > .layoutFlow > .elText4 {
    line-height: 19px;
    position: relative;
    margin-top: 28px;
    width: 237px;
    margin-left: 20px;
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elText4 > * { 
  width: 237px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .SolicitarUnFavorScreen > .layoutFlow > .elField {
    position: relative;
    margin-top: 3px;
    width: 80px;
    margin-left: 20px;
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elField > * { 
  width: 80px;
  }
  
@media (min-width: 568px) {
  .SolicitarUnFavorScreen > .layoutFlow > .elField {
    position: relative;
    margin-top: 3px;
    width: 80px;
    margin-left: 20px;
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elField > * { 
  width: 80px;
  }
  
}
@media (min-width: 768px) {
  .SolicitarUnFavorScreen > .layoutFlow > .elField {
    position: relative;
    margin-top: 3px;
    width: 80px;
    margin-left: 20px;
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elField > * { 
  width: 80px;
  }
  
}
@media (min-width: 1024px) {
  .SolicitarUnFavorScreen > .layoutFlow > .elField {
    position: relative;
    margin-top: 3px;
    width: 80px;
    margin-left: 20px;
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elField > * { 
  width: 80px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .SolicitarUnFavorScreen > .layoutFlow > .elText5 {
    line-height: 19px;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 20px;
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elText5 > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .SolicitarUnFavorScreen > .layoutFlow > .elText5 {
    line-height: 19px;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 20px;
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elText5 > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .SolicitarUnFavorScreen > .layoutFlow > .elText5 {
    line-height: 19px;
    position: relative;
    margin-top: 20px;
    width: 237px;
    margin-left: 20px;
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elText5 > * { 
  width: 237px;
  }
  
}
@media (min-width: 1024px) {
  .SolicitarUnFavorScreen > .layoutFlow > .elText5 {
    line-height: 19px;
    position: relative;
    margin-top: 20px;
    width: 237px;
    margin-left: 20px;
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elText5 > * { 
  width: 237px;
  }
  
}


.SolicitarUnFavorScreen > .layoutFlow > .elTextarea textarea {
  height: 100%;
}

/* Narrowest size for this element's responsive layout */
  .SolicitarUnFavorScreen > .layoutFlow > .elTextarea {
    position: relative;
    margin-top: 12px;
    margin-left: 20px;
    margin-right: 24px;
    width: calc(100.0% - 44px);
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elTextarea > * { 
    width: 100%;
  height: 181px;
  }
  
@media (min-width: 568px) {
  .SolicitarUnFavorScreen > .layoutFlow > .elTextarea {
    position: relative;
    margin-top: 12px;
    margin-left: 20px;
    margin-right: 24px;
    width: calc(100.0% - 44px);
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elTextarea > * { 
    width: 100%;
  height: 181px;
  }
  
}
@media (min-width: 768px) {
  .SolicitarUnFavorScreen > .layoutFlow > .elTextarea {
    position: relative;
    margin-top: 12px;
    margin-left: 20px;
    margin-right: 24px;
    width: calc(100.0% - 44px);
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elTextarea > * { 
    width: 100%;
  height: 180px;
  }
  
}
@media (min-width: 1024px) {
  .SolicitarUnFavorScreen > .layoutFlow > .elTextarea {
    position: relative;
    margin-top: 12px;
    margin-left: 20px;
    margin-right: 24px;
    width: calc(100.0% - 44px);
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elTextarea > * { 
    width: 100%;
  height: 180px;
  }
  
}

.SolicitarUnFavorScreen .elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.SolicitarUnFavorScreen .elButton button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .SolicitarUnFavorScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 24px;
    width: 145px;
    margin-left: 20px;
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elButton > * { 
  width: 145px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .SolicitarUnFavorScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 24px;
    width: 145px;
    margin-left: 20px;
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elButton > * { 
  width: 145px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .SolicitarUnFavorScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 24px;
    width: 144px;
    margin-left: 20px;
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elButton > * { 
  width: 144px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .SolicitarUnFavorScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 24px;
    width: 144px;
    margin-left: 20px;
  }
  .SolicitarUnFavorScreen > .layoutFlow > .elButton > * { 
  width: 144px;
  height: 35px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .SolicitarUnFavorScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    position: absolute;
    left: 296px;
    top: 576px;
    width: 23px;
    height: 23px;
  }
@media (min-width: 568px) {
  .SolicitarUnFavorScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    left: 296px;
    top: 576px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 768px) {
  .SolicitarUnFavorScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    left: 294px;
    top: 572px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 1024px) {
  .SolicitarUnFavorScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    left: 294px;
    top: 572px;
    width: 23px;
    height: 23px;
  }
}


/* Narrowest size for this element's responsive layout */
  .SolicitarUnFavorScreen > .screenFgContainer > .foreground > .elCongotoast {
    position: absolute;
    left: 285px;
    top: 588px;
    width: 23px;
    height: 23px;
  }
@media (min-width: 568px) {
  .SolicitarUnFavorScreen > .screenFgContainer > .foreground > .elCongotoast {
    left: 285px;
    top: 588px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 768px) {
  .SolicitarUnFavorScreen > .screenFgContainer > .foreground > .elCongotoast {
    left: 283px;
    top: 584px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 1024px) {
  .SolicitarUnFavorScreen > .screenFgContainer > .foreground > .elCongotoast {
    left: 283px;
    top: 584px;
    width: 23px;
    height: 23px;
  }
}


/* Narrowest size for this element's responsive layout */
  .SolicitudesPendientesScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .SolicitudesPendientesScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .SolicitudesPendientesScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .SolicitudesPendientesScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .SolicitudesPendientesScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .SolicitudesPendientesScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .SolicitudesPendientesScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .SolicitudesPendientesScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .SolicitudesPendientesScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .SolicitudesPendientesScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .SolicitudesPendientesScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .SolicitudesPendientesScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}

.SolicitudesPendientesScreen .elList li {
  margin-bottom: 5px;
}



/* Narrowest size for this element's responsive layout */
  .SolicitudesPendientesScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 6px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .SolicitudesPendientesScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .SolicitudesPendientesScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 6px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .SolicitudesPendientesScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .SolicitudesPendientesScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 6px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .SolicitudesPendientesScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .SolicitudesPendientesScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 6px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .SolicitudesPendientesScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}

/* This component has a fixed-size layout */
  .Comp1 {
    min-width: 182px; /* This component has a fixed size */
  }


  .Comp1 > .layoutFlow > .elPicker {
    position: relative;
    margin-top: 0px;
    width: 181px;
    margin-left: 0px;
  }
  .Comp1 > .layoutFlow > .elPicker > * { 
  width: 181px;
  }
  

/* This component has a fixed-size layout */
  .Comp3 {
    min-width: 182px; /* This component has a fixed size */
  }


  .Comp3 > .layoutFlow > .elPicker {
    position: relative;
    margin-top: -4px;
    width: 181px;
    margin-left: 0px;
  }
  .Comp3 > .layoutFlow > .elPicker > * { 
  width: 181px;
  }
  


/* Narrowest size for this element's responsive layout */
  .CompartirPerfilScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .CompartirPerfilScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .CompartirPerfilScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .CompartirPerfilScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .CompartirPerfilScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .CompartirPerfilScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .CompartirPerfilScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .CompartirPerfilScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .CompartirPerfilScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .CompartirPerfilScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .CompartirPerfilScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .CompartirPerfilScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .CompartirPerfilScreen > .layoutFlow > .elText {
    line-height: 24px;
    position: relative;
    margin-top: 0px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .CompartirPerfilScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .CompartirPerfilScreen > .layoutFlow > .elText {
    line-height: 24px;
    position: relative;
    margin-top: 0px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .CompartirPerfilScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .CompartirPerfilScreen > .layoutFlow > .elText {
    line-height: 24px;
    position: relative;
    margin-top: 0px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .CompartirPerfilScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .CompartirPerfilScreen > .layoutFlow > .elText {
    line-height: 24px;
    position: relative;
    margin-top: 0px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .CompartirPerfilScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .CompartirPerfilScreen > .layoutFlow > .elText2 {
    line-height: 19px;
    position: relative;
    margin-top: 37px;
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100.0% - 60px);
  }
  .CompartirPerfilScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .CompartirPerfilScreen > .layoutFlow > .elText2 {
    line-height: 19px;
    position: relative;
    margin-top: 37px;
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100.0% - 60px);
  }
  .CompartirPerfilScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .CompartirPerfilScreen > .layoutFlow > .elText2 {
    line-height: 19px;
    position: relative;
    margin-top: 37px;
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100.0% - 60px);
  }
  .CompartirPerfilScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .CompartirPerfilScreen > .layoutFlow > .elText2 {
    line-height: 19px;
    position: relative;
    margin-top: 37px;
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100.0% - 60px);
  }
  .CompartirPerfilScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .CompartirPerfilScreen > .layoutFlow > .elQrwriter {
    position: relative;
    margin-top: 21px;
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100.0% - 60px);
  }
  .CompartirPerfilScreen > .layoutFlow > .elQrwriter > * { 
    width: 100%;
  height: 225px;
  }
  
@media (min-width: 568px) {
  .CompartirPerfilScreen > .layoutFlow > .elQrwriter {
    position: relative;
    margin-top: 21px;
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100.0% - 60px);
  }
  .CompartirPerfilScreen > .layoutFlow > .elQrwriter > * { 
    width: 100%;
  height: 225px;
  }
  
}
@media (min-width: 768px) {
  .CompartirPerfilScreen > .layoutFlow > .elQrwriter {
    position: relative;
    margin-top: 21px;
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100.0% - 60px);
  }
  .CompartirPerfilScreen > .layoutFlow > .elQrwriter > * { 
    width: 100%;
  height: 224px;
  }
  
}
@media (min-width: 1024px) {
  .CompartirPerfilScreen > .layoutFlow > .elQrwriter {
    position: relative;
    margin-top: 21px;
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100.0% - 60px);
  }
  .CompartirPerfilScreen > .layoutFlow > .elQrwriter > * { 
    width: 100%;
  height: 224px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ObtenerQRDelPerfilScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .ObtenerQRDelPerfilScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .ObtenerQRDelPerfilScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .ObtenerQRDelPerfilScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .ObtenerQRDelPerfilScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .ObtenerQRDelPerfilScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .ObtenerQRDelPerfilScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .ObtenerQRDelPerfilScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .ObtenerQRDelPerfilScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .ObtenerQRDelPerfilScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .ObtenerQRDelPerfilScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .ObtenerQRDelPerfilScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ObtenerQRDelPerfilScreen > .layoutFlow > .elText {
    line-height: 19px;
    position: relative;
    margin-top: 12px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .ObtenerQRDelPerfilScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .ObtenerQRDelPerfilScreen > .layoutFlow > .elText {
    line-height: 19px;
    position: relative;
    margin-top: 12px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .ObtenerQRDelPerfilScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .ObtenerQRDelPerfilScreen > .layoutFlow > .elText {
    line-height: 19px;
    position: relative;
    margin-top: 12px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .ObtenerQRDelPerfilScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .ObtenerQRDelPerfilScreen > .layoutFlow > .elText {
    line-height: 19px;
    position: relative;
    margin-top: 12px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .ObtenerQRDelPerfilScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ObtenerQRDelPerfilScreen > .layoutFlow > .flowRow > .elQRReader2 {
    position: absolute;
    margin-top: 13px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .ObtenerQRDelPerfilScreen > .layoutFlow > .flowRow > .elQRReader2 > * { 
    width: 100%;
  height: 373px;
  }
  
  .flowRow_ObtenerQRDelPerfilScreen_elQRReader2_352437 { 
  }
  
@media (min-width: 568px) {
  .ObtenerQRDelPerfilScreen > .layoutFlow > .flowRow > .elQRReader2 {
    position: absolute;
    margin-top: 13px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .ObtenerQRDelPerfilScreen > .layoutFlow > .flowRow > .elQRReader2 > * { 
    width: 100%;
  height: 373px;
  }
  
  .flowRow_ObtenerQRDelPerfilScreen_elQRReader2_352437 { 
  }
  
}
@media (min-width: 768px) {
  .ObtenerQRDelPerfilScreen > .layoutFlow > .flowRow > .elQRReader2 {
    position: absolute;
    margin-top: 13px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .ObtenerQRDelPerfilScreen > .layoutFlow > .flowRow > .elQRReader2 > * { 
    width: 100%;
  height: 371px;
  }
  
  .flowRow_ObtenerQRDelPerfilScreen_elQRReader2_352437 { 
  }
  
}
@media (min-width: 1024px) {
  .ObtenerQRDelPerfilScreen > .layoutFlow > .flowRow > .elQRReader2 {
    position: absolute;
    margin-top: 13px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .ObtenerQRDelPerfilScreen > .layoutFlow > .flowRow > .elQRReader2 > * { 
    width: 100%;
  height: 371px;
  }
  
  .flowRow_ObtenerQRDelPerfilScreen_elQRReader2_352437 { 
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ObtenerQRDelPerfilScreen > .layoutFlow > .elImage {
    position: relative;
    margin-top: 3px;
    width: 100%;
  }
  .ObtenerQRDelPerfilScreen > .layoutFlow > .elImage > * { 
  margin-left: auto;
  width: 60px;
  margin-right: 8px;
  height: 102px;
  }
  
@media (min-width: 568px) {
  .ObtenerQRDelPerfilScreen > .layoutFlow > .elImage {
    position: relative;
    margin-top: 3px;
    width: 100%;
  }
  .ObtenerQRDelPerfilScreen > .layoutFlow > .elImage > * { 
  margin-left: auto;
  width: 60px;
  margin-right: 8px;
  height: 102px;
  }
  
}
@media (min-width: 768px) {
  .ObtenerQRDelPerfilScreen > .layoutFlow > .elImage {
    position: relative;
    margin-top: 3px;
    width: 100%;
  }
  .ObtenerQRDelPerfilScreen > .layoutFlow > .elImage > * { 
  margin-left: auto;
  width: 60px;
  margin-right: 8px;
  height: 102px;
  }
  
}
@media (min-width: 1024px) {
  .ObtenerQRDelPerfilScreen > .layoutFlow > .elImage {
    position: relative;
    margin-top: 3px;
    width: 100%;
  }
  .ObtenerQRDelPerfilScreen > .layoutFlow > .elImage > * { 
  margin-left: auto;
  width: 60px;
  margin-right: 8px;
  height: 102px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ObtenerQRDelPerfilScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    position: absolute;
    left: 300px;
    top: 608px;
    width: 23px;
    height: 23px;
  }
@media (min-width: 568px) {
  .ObtenerQRDelPerfilScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    left: 300px;
    top: 608px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 768px) {
  .ObtenerQRDelPerfilScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    left: 298px;
    top: 604px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 1024px) {
  .ObtenerQRDelPerfilScreen > .screenFgContainer > .foreground > .elSupabaseJS {
    left: 298px;
    top: 604px;
    width: 23px;
    height: 23px;
  }
}

/* This component has a fixed-size layout */
  .Button1 {
    min-height: 34px;  /* This component has a fixed size */
    overflow: hidden;
    min-width: 91px; /* This component has a fixed size */
  }
  .Button1 > .background > .containerMinHeight {
    min-height: 34px;
  }


  .Button1 > .foreground > .state0_elTitle {
    overflow: hidden;
    position: absolute;
    left: 4px;
    top: 4px;
    width: 82px;
    height: 28px;
  }

/* This component has a fixed-size layout */
  .Button1 {
    min-height: 34px;  /* This component has a fixed size */
    overflow: hidden;
    min-width: 91px; /* This component has a fixed size */
  }
  .Button1 > .background > .containerMinHeight {
    min-height: 34px;
  }


  .Button1 > .foreground > .state1_elTitle {
    overflow: hidden;
    position: absolute;
    left: 4px;
    top: 4px;
    width: 82px;
    height: 28px;
  }


  .Button1 > .foreground > .state1_elSelectionMarker {
    position: absolute;
    left: 0px;
    top: 32px;
    width: 91px;
    height: 2px;
  }

/* This component has a fixed-size layout */
  .TwoColumnTableItem {
    min-height: 34px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 338px */
    height: 100%;
  }
  .TwoColumnTableItem > .background > .containerMinHeight {
    min-height: 34px;
  }


  .TwoColumnTableItem > .layoutFlow > .elText {
    line-height: 19px;
    position: relative;
    margin-top: 3px;
    width: 110px;
    margin-left: calc(50.0% + -150px);
  }
  .TwoColumnTableItem > .layoutFlow > .elText > * { 
  width: 110px;
  }
  


  .TwoColumnTableItem > .layoutFlow > .elText2 {
    line-height: 19px;
    position: relative;
    margin-top: -20px;
    width: 174px;
    margin-left: calc(50.0% + -20px);
  }
  .TwoColumnTableItem > .layoutFlow > .elText2 > * { 
  width: 174px;
  }
  


  .TwoColumnTableItem > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 3px;
    width: 10px;
    margin-left: 155px;
  }
  .TwoColumnTableItem > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  

/* This component has a fixed-size layout */
  .Comp5 {
    width: 100%; /* This component was designed using a width of 376px */
    height: 100%;
  }


  .Comp5 > .background > .elCard {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 153px;
  }

.Comp5 .elIconButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Comp5 .elIconButton:focus {
  outline: none;
}



  .Comp5 > .background > .elIconButton {
    position: absolute;
    left: 318px;
    top: 179px;
    width: 41px;
    height: 36px;
  }


  .Comp5 > .layoutFlow > .elText2 {
    line-height: 24px;
    position: relative;
    margin-top: 6px;
    width: 290px;
    margin-left: 20px;
  }
  .Comp5 > .layoutFlow > .elText2 > * { 
  width: 290px;
  }
  

.Comp5 .elWarning button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Comp5 .elWarning button:focus {
  outline: none;
}



  .Comp5 > .layoutFlow > .elWarning {
    position: relative;
    margin-top: -23px;
    width: 100%;
  }
  .Comp5 > .layoutFlow > .elWarning > * { 
  margin-left: auto;
  width: 41px;
  margin-right: 5px;
  height: 36px;
  }
  


  .Comp5 > .layoutFlow > .elText {
    line-height: 19px;
    position: relative;
    margin-top: 0px;
    width: 185px;
    margin-left: 20px;
  }
  .Comp5 > .layoutFlow > .elText > * { 
  width: 185px;
  }
  


  .Comp5 > .layoutFlow > .elText3 {
    line-height: 24px;
    position: relative;
    margin-top: -20px;
    width: 104px;
    margin-left: 200px;
  }
  .Comp5 > .layoutFlow > .elText3 > * { 
  width: 104px;
  }
  


  .Comp5 > .layoutFlow > .elWarningtext {
    line-height: 19px;
    position: relative;
    margin-top: 14px;
    width: 341px;
    margin-left: 20px;
  }
  .Comp5 > .layoutFlow > .elWarningtext > * { 
  width: 341px;
  }
  


  .Comp5 > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 10px;
    width: 10px;
    margin-left: 0px;
  }
  .Comp5 > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  

/* This component has a fixed-size layout */
  .RequestsList {
    width: 100%; /* This component was designed using a width of 288px */
    height: 100%;
  }


  .RequestsList > .layoutFlow > .elFrom {
    line-height: 24px;
    position: relative;
    margin-top: 7px;
    margin-left: 10px;
    margin-right: 93px;
    width: calc(100.0% - 103px);
  }
  .RequestsList > .layoutFlow > .elFrom > * { 
    width: 100%;
  }
  


  .RequestsList > .layoutFlow > .elHours {
    line-height: 24px;
    position: relative;
    margin-top: -23px;
    width: 100%;
  }
  .RequestsList > .layoutFlow > .elHours > * { 
  margin-left: auto;
  width: 80px;
  margin-right: 11px;
  }
  


  .RequestsList > .layoutFlow > .elSkill {
    line-height: 19px;
    position: relative;
    margin-top: 7px;
    margin-left: 10px;
    margin-right: 93px;
    width: calc(100.0% - 103px);
  }
  .RequestsList > .layoutFlow > .elSkill > * { 
    width: 100%;
  }
  


  .RequestsList > .layoutFlow > .elDate {
    line-height: 19px;
    position: relative;
    margin-top: -19px;
    width: 100%;
  }
  .RequestsList > .layoutFlow > .elDate > * { 
  margin-left: auto;
  width: 80px;
  margin-right: 12px;
  }
  


  .RequestsList > .layoutFlow > .elMessage {
    line-height: 19px;
    position: relative;
    margin-top: 7px;
    margin-left: 10px;
    margin-right: 12px;
    width: calc(100.0% - 22px);
  }
  .RequestsList > .layoutFlow > .elMessage > * { 
    width: 100%;
  }
  


  .RequestsList > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 19px;
    width: 10px;
    margin-left: 150px;
  }
  .RequestsList > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  

.RequestsList .elRespondButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.RequestsList .elRespondButton button:focus {
  outline: none;
}



  .RequestsList > .layoutFlow > .elRespondButton {
    line-height: 35px;
    position: relative;
    margin-top: 0px;
    width: 176px;
    margin-left: calc(50.0% + -88px);
  }
  .RequestsList > .layoutFlow > .elRespondButton > * { 
  width: 176px;
  height: 35px;
  }
  

.RequestsList .elRespondButton2 button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.RequestsList .elRespondButton2 button:focus {
  outline: none;
}



  .RequestsList > .layoutFlow > .elRespondButton2 {
    line-height: 35px;
    position: relative;
    margin-top: 0px;
    width: 158px;
    margin-left: calc(50.0% + -79px);
  }
  .RequestsList > .layoutFlow > .elRespondButton2 > * { 
  width: 158px;
  height: 35px;
  }
  


  .RequestsList > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 4px;
    width: 10px;
    margin-left: 80px;
  }
  .RequestsList > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  

